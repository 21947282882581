import { useEffect, useState } from 'react';
import { useEligibilityBinding } from 'growth-payments-core/eligibility/eligibilityBinding';
import { calculateKYCStatus, hasCompletedStripe } from 'growth-payments-core/eligibility/utils';
import useCommerceToolStatus from './useCommerceToolStatus';
const useCommerceEnrollmentStatus = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [eligibility] = useEligibilityBinding();
  const {
    toolUsage,
    isLoading: isToolUsageLoading
  } = useCommerceToolStatus();
  useEffect(() => {
    if (eligibility && !isToolUsageLoading) {
      setIsLoading(false);
    }
  }, [eligibility, isToolUsageLoading]);
  const stripeCompleted = hasCompletedStripe(calculateKYCStatus(eligibility === null || eligibility === void 0 ? void 0 : eligibility.underwritingStatus));
  return {
    toolUsage,
    isEnrolledInPayments: stripeCompleted,
    isLoading
  };
};
export default useCommerceEnrollmentStatus;