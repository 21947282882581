import { ProcessorType } from 'growth-payments-core/eligibility/types';
import { hasCompletedHubSpot, KYCStatus, useKYCStatus } from 'growth-payments-core/eligibility/utils';

// Note: The eligibility input argument is temporary until we start using the new `useSyncExternalStore` hook to avoid "tearing".
// Refer tp this comment - https://git.hubteam.com/HubSpot/growth-payments-lib/pull/156/files/f85998c01fed734122b946eae755d5408f73b439#r2071010
export const useHasStartedHSPaymentsApplication = eligibility => {
  const hasMaybeHSPaymentsProcessorType = !!eligibility && (eligibility.processorType === ProcessorType.NONE || eligibility.processorType === ProcessorType.HS_PAYMENTS);
  const kycStatus = useKYCStatus();
  const hasCompletedApplicationFromKYCBinding = !!kycStatus && kycStatus >= KYCStatus.COMPLETED_HUBSPOT;
  const hasHSCompletedApplication = eligibility ? hasCompletedHubSpot(eligibility) : hasCompletedApplicationFromKYCBinding;
  return {
    loading: !eligibility && !kycStatus,
    hasStartedHSPaymentsApplication: hasHSCompletedApplication && hasMaybeHSPaymentsProcessorType
  };
};