import { useMemo } from 'react';
import { isCustomizableLocation } from '../types/Location';
import { useEnabledLocationsForObjectType } from './useEnabledLocationsForObjectType';

/**
 * Custom hook to determine if a specific object type and location are enabled in the crmPageEditor Framework App Settings.
 * For HubSpot-defined object types, this checks FAS to determine which locations are enabled.
 * For portal-specific and App object types, all customizable locations are enabled by default.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {string} params.objectTypeId - The object type ID to check.
 * @param {CustomizableLocation} params.location - The location to check.
 * @param {string[]} params.scopes - The scopes to check.
 * @param {string[]} params.gates - The gates to check.
 * @returns {Object} - An object containing:
 *   - `isLocationEnabledForObjectType` (boolean): Whether customization of the location is enabled for the object type.
 *   - `loading` (boolean): Whether the app settings data is currently loading.
 *   - `error` (Error | undefined): Any error that occurred while fetching the app settings data.
 *
 * @example
 * ```ts
 * const { isLocationEnabledForObjectType, loading, error } = useIsLocationEnabledForObjectType({
 *   objectTypeId: '0-5',
 *   location: 'CRM_RECORD_PAGE',
 *   scopes: [...],
 *   gates: [...],
 * });
 *
 * console.log(isLocationEnabledForObjectType);
 * // true
 * ```
 */
export const useIsLocationEnabledForObjectType = ({
  objectTypeId,
  location,
  scopes,
  gates
}) => {
  const {
    locations,
    loading,
    error
  } = useEnabledLocationsForObjectType({
    objectTypeId,
    scopes,
    gates
  });
  return useMemo(() => {
    if (!isCustomizableLocation(location)) {
      return {
        isLocationEnabledForObjectType: false,
        loading: false,
        error: undefined
      };
    }
    return {
      isLocationEnabledForObjectType: locations.includes(location),
      loading,
      error
    };
  }, [error, loading, location, locations]);
};