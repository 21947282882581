module.exports = {
  "productPanelUsage": {
    "name": "Product panel",
    "properties": {
      "action": [
        "Create product",
        "Edit product",
        "Clone product"
      ],
      "subAction": [
        "Save",
        "Save and add another"
      ],
      "propertyName": {
        "type": "array"
      },
      "billingFrequency": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "products",
    "class": "usage"
  },
  "productExport": {
    "name": "Products export",
    "properties": {
      "action": [
        "Export success"
      ],
      "subAction": [
        "Export specific objects",
        "Export all objects"
      ],
      "containsSearchQuery": {
        "type": "boolean"
      }
    },
    "namespace": "products",
    "class": "usage"
  },
  "deleteObject": {
    "properties": {
      "action": [
        "Delete product",
        "Delete folder"
      ]
    },
    "namespace": "products",
    "name": "Products usage",
    "class": "usage"
  },
  "bulkDeleteObjects": {
    "properties": {
      "action": [
        "Bulk delete"
      ],
      "containsSearchQuery": {
        "type": "boolean"
      }
    },
    "namespace": "products",
    "name": "Products usage",
    "class": "usage"
  },
  "modifyFolderModal": {
    "properties": {
      "action": [
        "Create folder",
        "Edit folder"
      ]
    },
    "namespace": "products",
    "name": "Products usage",
    "class": "usage"
  },
  "moveProductToFolder": {
    "properties": {
      "action": [
        "Move product to folder"
      ]
    },
    "namespace": "products",
    "name": "Products usage",
    "class": "usage"
  },
  "openProductOrLineItemPanel": {
    "properties": {
      "action": [
        "create",
        "edit",
        "view"
      ],
      "objectType": [
        "PRODUCT",
        "LINE_ITEM"
      ],
      "sourceIsShopify": "boolean",
      "sourceIsEcommBridge": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction"
  },
  "toggleFilePickerForImageURL": {
    "properties": {
      "action": [
        "Open file picker for image URL",
        "Close file picker for image URL"
      ],
      "objectType": [
        "PRODUCT",
        "LINE_ITEM"
      ]
    },
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction"
  },
  "selectFileForImageURL": {
    "properties": {
      "action": [
        "Select file for image URL"
      ],
      "objectType": [
        "PRODUCT",
        "LINE_ITEM"
      ]
    },
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction"
  },
  "productLibraryFilter": {
    "properties": {
      "action": [
        "Filter product library"
      ]
    },
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction"
  },
  "productPaymentLinkModalInteraction": {
    "properties": {
      "action": [
        "View modal",
        "Create Payment Link"
      ]
    },
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction"
  },
  "createObjectFromProductModal": {
    "properties": {
      "action": [
        "Create Invoice",
        "Create Payment Link",
        "Create Subscription",
        "Close modal"
      ]
    },
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction"
  },
  "productsSetup": {
    "properties": {
      "action": [
        "Navigate to manage product properties page",
        "Navigate to customize create product form page",
        "Navigate to product library",
        "Navigate to folder settings"
      ]
    },
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction"
  },
  "editProductTiers": {
    "properties": {
      "action": [
        "Add tier",
        "Edit tier",
        "Delete tier",
        "Edit tier price",
        "Edit tier start"
      ]
    },
    "namespace": "products",
    "name": "Products interaction",
    "class": "interaction"
  }
};