import { useContext } from 'react';
import { useThemeMetadata } from 'foundations-theming/components/ThemeProvider';
import { ThemeContext } from 'styled-components';
import { MAGENTA, ORANGE, GRADIENT } from 'ai-components-ui-library/styles/colors';
export const useTheme = () => {
  const theme = useContext(ThemeContext);
  const themeMetadata = useThemeMetadata();
  const breezeColors = {
    primary: {
      dark: MAGENTA[600],
      medium: MAGENTA[500],
      default: MAGENTA[400],
      light: MAGENTA[300],
      extraLight: MAGENTA[200],
      '2xLight': MAGENTA[100]
    },
    secondary: {
      default: ORANGE[400],
      light: ORANGE[300],
      extraLight: ORANGE[200],
      '2xLight': ORANGE[100]
    },
    gradient: {
      light: GRADIENT.LIGHT,
      medium: GRADIENT.MEDIUM,
      dark: GRADIENT.DARK
    }
  };
  return Object.assign({}, theme, {
    breezeColors,
    themeMetadata
  });
};