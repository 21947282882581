import { useApolloClient as useGraphqlClient } from '@apollo/client';
import { useCardLocationContext } from 'crm-cards-sdk/internal/CardLocationContext';
import { useDataFetchingClient } from 'data-fetching-client';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import deepEquals from './utils/deepEquals';
export function useDataResolver({
  cardData,
  registry,
  shouldRenderCard
}) {
  const graphqlClient = useGraphqlClient();
  const dfcClient = useDataFetchingClient();
  const {
    additionalLocationData
  } = useCardLocationContext();
  const lastRequestContextRef = useRef();
  const fetchData = useCallback(requestContext => {
    var _registry$cardDataRes;
    lastRequestContextRef.current = requestContext;
    const retval = registry === null || registry === void 0 || (_registry$cardDataRes = registry.cardDataResolver) === null || _registry$cardDataRes === void 0 ? void 0 : _registry$cardDataRes.call(registry, {
      graphqlClient,
      dfcClient
    }, requestContext);
    return retval;
  }, [registry, graphqlClient, dfcClient]);

  // kick off the dataResolver request on the first render and store it in a ref
  const dataResolverRef = useRef();
  const [resolvedDataState, setResolvedDataState] = useState({
    loading: !!dataResolverRef.current
  });

  // If this is the first time we're rendering the card and the card should render
  useLayoutEffect(() => {
    const shouldMakeFirstFetch = shouldRenderCard && !dataResolverRef.current;
    const shouldRefetch = lastRequestContextRef.current && !deepEquals(lastRequestContextRef.current, {
      cardData,
      additionalLocationData
    });
    if (shouldMakeFirstFetch || shouldRefetch) {
      dataResolverRef.current = fetchData({
        cardData,
        additionalLocationData
      });
      if (dataResolverRef.current) {
        setResolvedDataState({
          loading: true
        });
        dataResolverRef.current.then(data => {
          setResolvedDataState({
            loading: false,
            data
          });
        }).catch(e => {
          console.error(e);
          setResolvedDataState({
            loading: false,
            error: e
          });
        });
      }
    }
  }, [shouldRenderCard, fetchData, cardData, additionalLocationData]);
  return resolvedDataState;
}