import { buildCrmCard } from 'crm-cards-sdk/buildCrmCard';
import { useGetHasFeature } from 'crm-cards-sdk/hooks/useGetHasFeature';
import { useGetIsUngated } from 'crm-cards-sdk/hooks/useGetIsUngated';
import { useGetIsScoped } from 'crm-cards-sdk/hooks/useGetIsScoped';
import { getObjectFromContext } from './context/getObjectsFromContext';
import AssociatedObjectsCardContentSdkDataProvider from './card/content/AssociatedObjectsCardContentSdkDataProvider';
import AssociatedObjectsCardTitleSdkDataProvider from './card/title/AssociatedObjectsCardTitleSdkDataProvider';
import AssociatedObjectsCardActionsSdkDataProvider from './card/actions/AssociatedObjectsCardActionsSdkDataProvider';
import { AssociatedObjectsCardDataContext } from './context/AssociatedObjectsCardDataContext';
import { useFetchAssociatedObjectsCardData } from './hooks/useFetchAssociatedObjectsCardData';
import once from 'transmute/once';
const useCardActions = once(() => () => Promise.resolve(AssociatedObjectsCardActionsSdkDataProvider));
const AssociatedObjectsCardSdk = buildCrmCard({
  displayName: 'AssociatedObjectsCardSdk',
  useLegacyDataAttributes: ({
    cardData
  }) => ({
    'data-sidebar-card-association-object-type-id': cardData.configuration.toObjectTypeId,
    'data-sidebar-key': cardData.definition.cardTitle,
    'data-sidebar-card-type': 'AssociatedObjectsCard'
  }),
  ContentComponent: AssociatedObjectsCardContentSdkDataProvider,
  context: AssociatedObjectsCardDataContext,
  useContextValue: useFetchAssociatedObjectsCardData,
  useCardProps: (props, context) => {
    const {
      propertySource
    } = context;
    const {
      objectId,
      objectTypeId
    } = getObjectFromContext(context);
    const isUngated = useGetIsUngated();
    const hasScope = useGetIsScoped();
    const hasFeature = useGetHasFeature();
    return {
      cardId: props.cardData.definition.cardId,
      hasFeature,
      hasScope,
      isCollapsed: props.cardData.isCollapsed,
      isUngated,
      objectId,
      objectTypeId,
      propertyNames: props.cardData.configuration.propertyNames,
      sortConfiguration: props.cardData.configuration.sortConfiguration,
      readOnly: Boolean(context.readOnly),
      toObjectTypeId: props.cardData.configuration.toObjectTypeId,
      title: props.cardData.definition.cardTitle,
      propertySource
    };
  },
  enableLegacyActionFormat: true,
  // When `enableLegacyActionFormat: true` buildCrmCard expects useCardActions to return a function that resolves actions component as a promise.
  // This logic is build based on the assumption that the component is code split and loaded async.
  // We don't want AssociatedObjectsCardActionsSdkDataProvider to be code split but we still need to wrap it in a Promise to make it work with buildCrmCard.
  useCardActions,
  TitleComponent: AssociatedObjectsCardTitleSdkDataProvider
});
export default AssociatedObjectsCardSdk;