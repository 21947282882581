import { getWindow } from './getWindow';
export function isOnSubjectRecord(objectType, subjectId) {
  if (!objectType || !subjectId) {
    return false;
  }
  // pathname is "/contacts/<portalId>/<objectType>/<objectId>"
  //          or "/contacts/<portalId>/record/<objectType>/<objectId>"
  const routeBasePattern = /^\/contacts\/\d+\//;
  const window = getWindow();
  if (window.location.pathname.match(routeBasePattern)) {
    const segments = window.location.pathname.split('/');
    if (segments[3] === 'record') {
      const currentPageObjectType = segments[4];
      const currentPageSubjectId = segments[5];
      return currentPageObjectType === objectType && currentPageSubjectId === String(subjectId);
    } else {
      const currentPageObjectType = segments[3];
      const currentPageSubjectId = segments[4];
      return currentPageObjectType.toLowerCase() === objectType.toLowerCase() && currentPageSubjectId === String(subjectId);
    }
  }
  return false;
}