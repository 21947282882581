import { useEffect, useRef, useCallback } from 'react';
import { useOnCrmMessageTopic } from 'crm-message-bus/useOnCrmMessageTopic';
import { Metrics } from '../../utils/Metrics';
import debounce from 'transmute/debounce';
const LEFT_SELECTOR = '[data-scroll-trackable-container="left-sidebar"]';
const MIDDLE_SELECTOR = '[data-scroll-trackable-container="middle-column"]';
const RIGHT_SELECTOR = '[data-scroll-trackable-container="right-sidebar"]';
const METRICS_KEY_SCROLL_DISTANCE_RECORD_TOTAL = 'scroll-distance-record-total';
const METRICS_KEY_SCROLL_DISTANCE_ELEMENT_NOT_FOUND = 'scroll-distance-element-not-found';
const METRICS_KEY_SCROLL_DISTANCE_ELEMENT_FOUND = 'scroll-distance-element-found';

// When the user has stopped scrolling, wait this long before reporting the scroll distances
const DEBOUNCE_REPORT_DELAY = 10000;
// Wait this long before querying the DOM for the scroll elements
const INITIAL_DOM_QUERY_DELAY = 1000;

/**
 * Known issues:
 * 1. If a user scrolls one section and then quickly (within half a second) scrolls another section, the new positions
 *    are sometimes not updated - but the new position will be corrected on the next scroll of that section, which will
 *    correct the tracking data for the report.
 *    This would only happen if a user is not reading what they scroll to before scrolling another section, so I expect it to be rare.
 * 2. If a user uses their mouse to drag the scrollbar from the top, to the bottom, back to the top, the difference is 0,
 *    so no scrolling is tracked, because the difference is based on where the scrollbar ended.
 *    This is not an issue when scrolling with mousewheel.
 * 3. If a user closes the tab before the reporting delay, the scrolling data is not reported.
 *    Note that if a user navigates to a different route (like an associated record), scroll data is still reported.
 */

export const ScrollTracker = () => {
  // Refs to store scroll handlers for useEffect cleanup
  const leftScrollHandlerRef = useRef(null);
  const middleScrollHandlerRef = useRef(null);
  const rightScrollHandlerRef = useRef(null);
  const isActivitiesTabRef = useRef(false);

  // Listen for tab changes to determine if the activities tab is open
  useOnCrmMessageTopic('CONTAINER_TAB_OPENED', data => {
    isActivitiesTabRef.current = data.isActivitiesTab;
  });

  // Refs to store scroll positions
  const leftScrollRef = useRef(0);
  const middleScrollRef = useRef(0);
  const rightScrollRef = useRef(0);

  // Refs to store individual scroll differences
  const leftScrollDifferenceRef = useRef(0);
  const middleScrollDifferenceRef = useRef(0);
  const rightScrollDifferenceRef = useRef(0);
  const reportScrollDifferences = useCallback(() => {
    const totalScrollDifference = leftScrollDifferenceRef.current + middleScrollDifferenceRef.current + rightScrollDifferenceRef.current;
    const activitySummaryElement = document.querySelector('#activity-summary');
    Metrics.histogram(METRICS_KEY_SCROLL_DISTANCE_RECORD_TOTAL, {
      location: 'TOTAL'
    }).update(totalScrollDifference);
    Metrics.histogram(METRICS_KEY_SCROLL_DISTANCE_RECORD_TOTAL, {
      location: 'LEFT'
    }).update(leftScrollDifferenceRef.current);
    Metrics.histogram(METRICS_KEY_SCROLL_DISTANCE_RECORD_TOTAL, {
      location: 'MIDDLE',
      isActivitiesTab: isActivitiesTabRef.current.toString(),
      activitySummaryVisible: String(!!activitySummaryElement)
    }).update(middleScrollDifferenceRef.current);
    Metrics.histogram(METRICS_KEY_SCROLL_DISTANCE_RECORD_TOTAL, {
      location: 'RIGHT'
    }).update(rightScrollDifferenceRef.current);
    leftScrollDifferenceRef.current = 0;
    middleScrollDifferenceRef.current = 0;
    rightScrollDifferenceRef.current = 0;
  }, []);
  const debouncedReportScrollDifferencesRef = useRef(debounce(DEBOUNCE_REPORT_DELAY, reportScrollDifferences));
  const addScrollListener = useCallback((metricsKey, querySelector, scrollRef, scrollDifferenceRef, handlerRef) => {
    const handleScroll = event => {
      const scrolledElement = event.target;
      if (!scrolledElement) {
        return;
      }
      const newScrollPosition = scrolledElement.scrollTop;
      const scrollDifference = Math.abs(Math.round(newScrollPosition - scrollRef.current));
      scrollRef.current = newScrollPosition;
      scrollDifferenceRef.current += scrollDifference;
      debouncedReportScrollDifferencesRef.current();
    };
    const element = document.querySelector(querySelector);
    const hasScrollendBrowserSupport = element && 'onscrollend' in element;
    if (!hasScrollendBrowserSupport) {
      return;
    }
    // 1. Report when element was found vs not, to track if the DOM selectors are working as expected
    // 2. It will also not be found if the right sidebar is collapsed
    // 3. Report this after the short-circuit for !hasScrollendBrowserSupport to only report this metric when there exists browser support
    if (!element) {
      Metrics.counter(METRICS_KEY_SCROLL_DISTANCE_ELEMENT_NOT_FOUND, {
        location: metricsKey
      }).increment();
      return;
    }
    Metrics.counter(METRICS_KEY_SCROLL_DISTANCE_ELEMENT_FOUND, {
      location: metricsKey
    }).increment();
    element.addEventListener('scrollend', handleScroll);
    handlerRef.current = handleScroll;
  }, []);
  const removeScrollListener = useCallback((querySelector, handlerRef) => {
    const element = document.querySelector(querySelector);
    if (element && handlerRef.current) {
      element.removeEventListener('scrollend', handlerRef.current);
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      addScrollListener('LEFT', LEFT_SELECTOR, leftScrollRef, leftScrollDifferenceRef, leftScrollHandlerRef);
      addScrollListener('MIDDLE', MIDDLE_SELECTOR, middleScrollRef, middleScrollDifferenceRef, middleScrollHandlerRef);
      addScrollListener('RIGHT', RIGHT_SELECTOR, rightScrollRef, rightScrollDifferenceRef, rightScrollHandlerRef);
    }, INITIAL_DOM_QUERY_DELAY);
    return () => {
      removeScrollListener(LEFT_SELECTOR, leftScrollHandlerRef);
      removeScrollListener(MIDDLE_SELECTOR, middleScrollHandlerRef);
      removeScrollListener(RIGHT_SELECTOR, rightScrollHandlerRef);
    };
  }, [addScrollListener, removeScrollListener]);
  return null;
};