import { buildCrmCard } from 'crm-cards-sdk/buildCrmCard';
import { getObjectFromContext } from '../utils/getObjectFromContext';
import { useGetIsUngated } from 'crm-cards-sdk/hooks/useGetIsUngated';
import { useGetIsScoped } from 'crm-cards-sdk/hooks/useGetIsScoped';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { useCardSettingsActionConfig } from 'crm-cards-sdk/hooks/useCardSettingsActionConfig';
import I18n from 'I18n';
import { useCallback, useMemo } from 'react';
import PortalIdParser from 'PortalIdParser';
import { getShowPropertiesAppLinks } from '../utils/getShowPropertiesAppLinks';
import { useEnrichmentMenuItem } from '../components/Enrichment/useEnrichmentMenuItem';
import DataProviderSdkWrapper from './components/DataProviderSdkWrapper';
const LINK_ACTION_TYPE = 'link';
const PropertiesCard = buildCrmCard({
  displayName: 'PropertiesCard',
  ContentComponent: DataProviderSdkWrapper,
  useLegacyDataAttributes: () => ({
    'data-sidebar-card-type': 'PropertiesCard',
    'data-sidebar-key': 'Properties'
  }),
  useCardProps: (props, context) => {
    const {
      editableAreasAtom,
      propertySource
    } = context;
    const {
      objectId,
      objectTypeId
    } = getObjectFromContext(context);
    const isUngated = useGetIsUngated();
    const hasScope = useGetIsScoped();
    return {
      cardId: props.cardData.definition.cardId,
      editableAreasAtom,
      hasScope,
      isUngated,
      objectId,
      objectTypeId,
      propertyNames: props.cardData.configuration.propertyNames,
      propertySource,
      readOnly: Boolean(context.readOnly),
      userPermission: props.cardData.configuration.userPermission
    };
  },
  useCardActions: (props, context) => {
    const {
      cardData: {
        definition: {
          cardId,
          cardType
        },
        configuration: {
          userPermission
        }
      },
      usageTracker
    } = props;
    const {
      objectTypeId,
      objectId
    } = getObjectFromContext(context);
    const adminSettingsConfig = useCardSettingsActionConfig();
    const openUserCustomizationPanel = useSendCrmMessageTopic(TOPIC_NAMES.OPEN_CARD_USER_CUSTOMIZATION);
    const handleTrackOnClick = useCallback(action => {
      usageTracker === null || usageTracker === void 0 || usageTracker.track('sidebar-interaction', {
        action,
        type: objectTypeId
      });
    }, [usageTracker, objectTypeId]);
    const eschref = `?eschref=${encodeURIComponent(window.location.pathname)}`;
    const hasScope = useGetIsScoped();
    const isUngated = useGetIsUngated();
    const {
      enrichmentMenuItems
    } = useEnrichmentMenuItem({
      hasScope,
      isUngated,
      objectId: String(objectId),
      objectTypeId
    });
    const actionsDropdownConfig = useMemo(() => {
      const portalId = PortalIdParser.get();
      const options = [];
      if (userPermission) {
        options.push({
          type: 'button',
          label: I18n.text('cardProperties.cardActions.customizeProperties'),
          onClick: () => {
            handleTrackOnClick('customize properties');
            openUserCustomizationPanel({
              cardId,
              cardType,
              objectTypeId
            });
          }
        });
      }
      if (getShowPropertiesAppLinks(objectTypeId)) {
        options.push({
          onClick: () => {
            handleTrackOnClick('view all properties');
          },
          type: LINK_ACTION_TYPE,
          label: I18n.text('cardProperties.cardActions.allProperties'),
          href: `/contacts/${portalId}/record/${objectTypeId}/${objectId}/properties${eschref}`
        }, {
          onClick: () => {
            handleTrackOnClick('view all property history');
          },
          type: LINK_ACTION_TYPE,
          label: I18n.text('cardProperties.cardActions.propertiesHistory'),
          href: `/contacts/${portalId}/record/${objectTypeId}/${objectId}/history${eschref}`
        });
      }
      options.push(...enrichmentMenuItems);
      if (options.length) {
        return {
          type: 'dropdown',
          label: I18n.text('cardProperties.cardActions.dropdownLabel'),
          options: options
        };
      }
      return null;
    }, [cardId, cardType, enrichmentMenuItems, eschref, handleTrackOnClick, objectId, objectTypeId, openUserCustomizationPanel, userPermission]);
    return useMemo(() => {
      const actions = [];
      if (actionsDropdownConfig) {
        actions.push(actionsDropdownConfig);
      }
      if (adminSettingsConfig) {
        actions.push(adminSettingsConfig);
      }
      return actions;
    }, [actionsDropdownConfig, adminSettingsConfig]);
  }
});
export default PropertiesCard;