export const ACTIVITIES = 'ACTIVITIES';
export const ACTIVITIES_V2 = 'ACTIVITIES_V2';
export const ACTIVITY_TOTALS = 'ACTIVITY_TOTALS';
export const ADVANCED_PLAYBOOKS = 'ADVANCED_PLAYBOOKS';
export const AI_OBJECT_SUMMARY = 'AI_OBJECT_SUMMARY';
export const ALLBOUND_TIMELINE = 'ALLBOUND_TIMELINE';
export const ASSOCIATION_PIVOT = 'ASSOCIATION_PIVOT';
export const ASSOCIATION_PROPERTIES_LIST = 'ASSOCIATION_PROPERTIES_LIST';
export const ASSOCIATION_STAGE_TRACKER = 'ASSOCIATION_STAGE_TRACKER';
export const ASSOCIATION_TABLE = 'ASSOCIATION_TABLE';
export const ASSOCIATION_V3 = 'ASSOCIATION_V3';
export const ATTACHMENTS = 'ATTACHMENTS';
export const BET_ASSOCIATED_COMPANIES = 'BET_ASSOCIATED_COMPANIES';
export const BET_CUSTOMER_SUMMARY = 'BET_CUSTOMER_SUMMARY';
export const BET_DEAL_SUPPORT_REQUEST = 'BET_DEAL_SUPPORT_REQUEST';
export const BET_PARTNER_COLLAB = 'BET_PARTNER_COLLAB';
export const BET_PARTNER_POI = 'BET_PARTNER_POI';
export const BET_QUOTES = 'BET_QUOTES';
export const BIZOPS_CUSTOMER_SUCCESS = 'BIZOPS_CUSTOMER_SUCCESS';
export const BIZOPS_DOMAIN_CONTROLS = 'BIZOPS_DOMAIN_CONTROLS';
export const BIZOPS_RELATED_CONTACTS = 'BIZOPS_RELATED_CONTACTS';
export const BUYER_INTENT = 'BUYER_INTENT';
export const CALL_SUMMARIES = 'CALL_SUMMARIES';
export const CART_DISCOUNT = 'CART_DISCOUNT';
export const CART_SHIPMENT_DETAILS = 'CART_SHIPMENT_DETAILS';
export const CART_TOTAL = 'CART_TOTAL';
export const COMMERCE_PAYMENT_HISTORY = 'COMMERCE_PAYMENT_HISTORY';
export const COMPANY_PARENT_CHILD = 'COMPANY_PARENT_CHILD';
export const COMPANY_RESEARCH_INSIGHTS = 'COMPANY_RESEARCH_INSIGHTS';
export const COMPANY_SUMMARY = 'COMPANY_SUMMARY';
export const CONTACT_CREATE_ATTRIBUTION = 'CONTACT_CREATE_ATTRIBUTION';
export const CONVERSATION_CONTACT_TICKETS = 'CONVERSATION_CONTACT_TICKETS';
export const CONVERSATION_DEALS = 'CONVERSATION_DEALS';
export const CONVERSATION_THREAD_TICKET = 'CONVERSATION_THREAD_TICKET';
export const CRM_EVENTS_VIZ_TIMELINE = 'CRM_EVENTS_VIZ_TIMELINE';
export const CRM_OBJECT_ASSOCIATION = 'CRM_OBJECT_ASSOCIATION';
export const CRM_REPORT = 'CRM_REPORT';
export const CUSTOMER_SPEND_TRENDS = 'CUSTOMER_SPEND_TRENDS';
export const CUSTOMER_SUCCESS_CONTACTS = 'CUSTOMER_SUCCESS_CONTACTS';
export const CUSTOMER_SUCCESS_HEALTH_SCORE = 'CUSTOMER_SUCCESS_HEALTH_SCORE';
export const DATA_HIGHLIGHTS = 'DATA_HIGHLIGHTS';
export const DATAWELL_REPORT = 'DATAWELL_REPORT';
export const DEAL_COLLABORATORS = 'DEAL_COLLABORATORS';
export const DEAL_CREATE_ATTRIBUTION = 'DEAL_CREATE_ATTRIBUTION';
export const DEAL_EXECUTION_ACTIVITIES = 'DEAL_EXECUTION_ACTIVITIES';
export const DEAL_REGISTRATION = 'DEAL_REGISTRATION';
export const DEAL_SCORE_WITHOUT_HISTORY = 'DEAL_SCORE_WITHOUT_HISTORY';
export const DEAL_SCORE_SUMMARY = 'DEAL_SCORE_SUMMARY';
export const DEAL_SCORE_SUMMARY_PREVIEW = 'DEAL_SCORE_SUMMARY_PREVIEW';
export const DEAL_SHARED_VIEW = 'DEAL_SHARED_VIEW';
export const DEAL_SPLITS = 'DEAL_SPLITS';
export const DEALS_SUMMARY_REPORT = 'DEALS_SUMMARY_REPORT';
export const DOCUSIGN = 'DOCUSIGN';
export const ENGAGEMENT_SUMMARY = 'ENGAGEMENT_SUMMARY';
export const EXTENSION = 'EXTENSION';
export const FEEDBACK = 'FEEDBACK';
export const FLYWHEEL_COMPANY_OVERVIEW = 'FLYWHEEL_COMPANY_OVERVIEW';
export const FLYWHEEL_HIGH_INTENT_ASSOCIATED_CONTACTS = 'FLYWHEEL_HIGH_INTENT_ASSOCIATED_CONTACTS';
export const FLYWHEEL_QL_SUMMARY = 'FLYWHEEL_QL_SUMMARY';
export const FLYWHEEL_RECENT_CONVERSIONS = 'FLYWHEEL_RECENT_CONVERSIONS';
export const GDPR_SUBSCRIPTIONS = 'GDPR_SUBSCRIPTIONS';
export const GUIDED_EXECUTION_ACTIVITIES = 'GUIDED_EXECUTION_ACTIVITIES';
export const HELP_DESK_AGENT_AVAILABILITY = 'HELP_DESK_AGENT_AVAILABILITY';
export const HELP_DESK_CALLING_PERFORMANCE = 'HELP_DESK_CALLING_PERFORMANCE';
export const HELP_DESK_KNOWLEDGE_BASE = 'HELP_DESK_KNOWLEDGE_BASE';
export const HELP_DESK_TEAM_SUMMARY = 'HELP_DESK_TEAM_SUMMARY';
export const HELP_DESK_SLA_PROGRESS = 'HELP_DESK_SLA_PROGRESS';
export const HELP_DESK_TICKETS_BY_CHANNEL = 'HELP_DESK_TICKETS_BY_CHANNEL';
export const HELP_DESK_TICKET_SUMMARY = 'HELP_DESK_TICKET_SUMMARY';
export const GUIDED_ACTIONS = 'GUIDED_ACTIONS';
export const GUIDED_SELLING_DEAL_SUMMARY = 'GUIDED_SELLING_DEAL_SUMMARY';
export const GUIDED_SELLING_STAGE_TRACKER = 'GUIDED_SELLING_STAGE_TRACKER';
export const INTEGRATION = 'INTEGRATION';
export const INTEGRATIONS_SYNC_STATUS = 'INTEGRATIONS_SYNC_STATUS';
export const INTELLIGENCE_CONTENT = 'INTELLIGENCE_CONTENT';
export const INTERNAL_STAKEHOLDERS = 'INTERNAL_STAKEHOLDERS';
export const INVOICES = 'INVOICES';
export const LEAD_QUALIFICATION = 'LEAD_QUALIFICATION';
export const LEAD_INSIGHTS = 'LEAD_INSIGHTS';
export const LEAD_STAGE_TRACKER = 'LEAD_STAGE_TRACKER';
export const LEGACY_SIDEBAR_CARD = 'LEGACY_SIDEBAR_CARD';
export const LINKEDIN_SALES_NAVIGATOR = 'LINKEDIN_SALES_NAVIGATOR';
export const LISTS = 'LISTS';
export const MARKETING_EVENT_ABOUT = 'MARKETING_EVENT_ABOUT';
export const MARKETING_EVENT_ACTIVITY = 'MARKETING_EVENT_ACTIVITY';
export const MARKETING_EVENT_ATTENDANCE = 'MARKETING_EVENT_ATTENDANCE';
export const MARKETING_EVENT_PARTICIPATION = 'MARKETING_EVENT_PARTICIPATION';
export const MARKETING_LEAD_SCORES = 'MARKETING_LEAD_SCORES';
export const NATIVE_INVOICES = 'NATIVE_INVOICES';
export const NATIVE_INVOICES_HISTORY = 'NATIVE_INVOICES_HISTORY';
export const NETSUITE = 'NETSUITE';
export const ORDER_DISCOUNT = 'ORDER_DISCOUNT';
export const ORDER_SHIPMENT_DETAIL = 'ORDER_SHIPMENT_DETAIL';
export const ORDER_TOTAL = 'ORDER_TOTAL';
export const OTHER_TICKETS = 'OTHER_TICKETS';
export const PARTNER_CLIENT_PRODUCT_USAGE_OUTCOMES = 'PARTNER_CLIENT_PRODUCT_USAGE_OUTCOMES';
export const PARTNER_CLIENT_UNIFIED_USAGE_SCORE = 'PARTNER_CLIENT_UNIFIED_USAGE_SCORE';
export const PARTNER_REGISTRATION = 'PARTNER_REGISTRATION';
export const PAST_CONVERSATIONS = 'PAST_CONVERSATIONS';
export const PAYMENT_SCHEDULE = 'PAYMENT_SCHEDULE';
export const PINNED_ACTIVITY = 'PINNED_ACTIVITY';
export const PIPELINE_APPROVALS = 'PIPELINE_APPROVALS';
export const PLAYBOOKS = 'PLAYBOOKS';
export const PROCESS_GUIDE_LIST = 'PROCESS_GUIDE_LIST';
export const PRODUCTS = 'PRODUCTS';
export const PRODUCT_PRICING_INFO = 'PRODUCT_PRICING_INFO';
export const PROPERTIES = 'PROPERTIES';
export const PROPERTIES_LIST = 'PROPERTIES_LIST';
export const PROPERTIES_V3 = 'PROPERTIES_V3';
export const PROPERTY_HISTORY = 'PROPERTY_HISTORY';
export const PROSPECTING_NOTES = 'PROSPECTING_NOTES';
export const PROSPECTING_RESEARCH_AGENT = 'PROSPECTING_RESEARCH_AGENT';
export const PROTOTYPE = 'PROTOTYPE';
export const QUALIFIED_LEADS = 'QUALIFIED_LEADS';
export const QUICK_ACTIONS = 'QUICK_ACTIONS';
export const QUOTES = 'QUOTES';
export const RECORD_TIMELINE = 'RECORD_TIMELINE';
export const RELATIVE_TIMELINE = 'RELATIVE_TIMELINE';
export const REVENUE_ATTRIBUTION = 'REVENUE_ATTRIBUTION';
export const SALES_ACTIVITIES_TIMELINE = 'SALES_ACTIVITIES_TIMELINE';
export const SERVICE_DELIVERY_HOURS_LOGGING = 'SERVICE_DELIVERY_HOURS_LOGGING';
export const SFDC_SYNC = 'SFDC_SYNC';
export const SIMPLE_DEADLINE = 'SIMPLE_DEADLINE';
export const SINGLETON = 'SINGLETON';
export const STAGE_TRACKER = 'STAGE_TRACKER';
export const STATISTICS = 'STATISTICS';
export const SUBSCRIPTION_BILLING_INFO = 'SUBSCRIPTION_BILLING_INFO';
export const SUBSCRIPTION_PAYMENTS_TIMELINE = 'SUBSCRIPTION_PAYMENTS_TIMELINE';
export const SURVEY_MONKEY = 'SURVEY_MONKEY';
export const TALKING_POINTS = 'TALKING_POINTS';
export const TARGET_ACCOUNT = 'TARGET_ACCOUNT';
export const TARGET_ACCOUNT_BUYER_COMMITTEE = 'TARGET_ACCOUNT_BUYER_COMMITTEE';
export const TARGET_ACCOUNT_DATAWELL = 'TARGET_ACCOUNT_DATAWELL';
export const TASKS_TIMELINE = 'TASKS_TIMELINE';
export const TOOL_LINKS = 'TOOL_LINKS';
export const TRANSCRIPT = 'TRANSCRIPT';
export const UNIVERSAL_TIMELINE = 'UNIVERSAL_TIMELINE';
export const UPCOMING_ACTIVITIES = 'UPCOMING_ACTIVITIES';
export const WEBSITE_ACTIVITY = 'WEBSITE_ACTIVITY';
export const WORKFLOWS = 'WORKFLOWS';
export const WORKFLOWS_INVOKER = 'WORKFLOWS_INVOKER';
export const ZORSE_CONVERSATIONS_TAXONOMY = 'ZORSE_CONVERSATIONS_TAXONOMY';
export const ZORSE_MOVE_INBOX = 'ZORSE_MOVE_INBOX';
export const ZORSE_REP_CHAT_ASSIGNMENT = 'ZORSE_REP_CHAT_ASSIGNMENT';
export const ZORSE_TICKET_SEARCH = 'ZORSE_TICKET_SEARCH';

// Strategic Integrations Cards
export const ADOBE_SIGN = 'ADOBE_SIGN';
export const DOCUSIGN_V2 = 'DOCUSIGN_V2';
export const PRODUCT_HISTORY = 'PRODUCT_HISTORY';
export const PURCHASE_DATA_WELL = 'PURCHASE_DATA_WELL';
export const SHOPIFY_OVERVIEW = 'SHOPIFY_OVERVIEW';
export const CARD_TYPES = {
  ACTIVITIES,
  ACTIVITIES_V2,
  ACTIVITY_TOTALS,
  ADOBE_SIGN,
  ADVANCED_PLAYBOOKS,
  AI_OBJECT_SUMMARY,
  ALLBOUND_TIMELINE,
  ASSOCIATION_PIVOT,
  ASSOCIATION_PROPERTIES_LIST,
  ASSOCIATION_STAGE_TRACKER,
  ASSOCIATION_TABLE,
  ASSOCIATION_V3,
  ATTACHMENTS,
  BET_ASSOCIATED_COMPANIES,
  BET_CUSTOMER_SUMMARY,
  BET_DEAL_SUPPORT_REQUEST,
  BET_PARTNER_COLLAB,
  BET_PARTNER_POI,
  BET_QUOTES,
  BIZOPS_CUSTOMER_SUCCESS,
  BIZOPS_DOMAIN_CONTROLS,
  BIZOPS_RELATED_CONTACTS,
  BUYER_INTENT,
  CALL_SUMMARIES,
  CART_DISCOUNT,
  CART_SHIPMENT_DETAILS,
  CART_TOTAL,
  COMMERCE_PAYMENT_HISTORY,
  COMPANY_PARENT_CHILD,
  COMPANY_RESEARCH_INSIGHTS,
  COMPANY_SUMMARY,
  CONTACT_CREATE_ATTRIBUTION,
  CONVERSATION_CONTACT_TICKETS,
  CONVERSATION_DEALS,
  CONVERSATION_THREAD_TICKET,
  CRM_EVENTS_VIZ_TIMELINE,
  // BE doesn't list this as a type in GraphQL CardType
  CRM_OBJECT_ASSOCIATION,
  CRM_REPORT,
  CUSTOMER_SPEND_TRENDS,
  CUSTOMER_SUCCESS_CONTACTS,
  CUSTOMER_SUCCESS_HEALTH_SCORE,
  DATA_HIGHLIGHTS,
  DATAWELL_REPORT,
  DEAL_COLLABORATORS,
  DEAL_CREATE_ATTRIBUTION,
  DEAL_EXECUTION_ACTIVITIES,
  DEAL_REGISTRATION,
  DEAL_SCORE_WITHOUT_HISTORY,
  DEAL_SCORE_SUMMARY,
  DEAL_SCORE_SUMMARY_PREVIEW,
  DEAL_SHARED_VIEW,
  DEAL_SPLITS,
  DEALS_SUMMARY_REPORT,
  DOCUSIGN,
  DOCUSIGN_V2,
  ENGAGEMENT_SUMMARY,
  EXTENSION,
  FEEDBACK,
  FLYWHEEL_COMPANY_OVERVIEW,
  FLYWHEEL_HIGH_INTENT_ASSOCIATED_CONTACTS,
  FLYWHEEL_QL_SUMMARY,
  FLYWHEEL_RECENT_CONVERSIONS,
  GDPR_SUBSCRIPTIONS,
  HELP_DESK_TEAM_SUMMARY,
  HELP_DESK_AGENT_AVAILABILITY,
  HELP_DESK_CALLING_PERFORMANCE,
  HELP_DESK_KNOWLEDGE_BASE,
  HELP_DESK_SLA_PROGRESS,
  HELP_DESK_TICKETS_BY_CHANNEL,
  HELP_DESK_TICKET_SUMMARY,
  GUIDED_ACTIONS,
  GUIDED_EXECUTION_ACTIVITIES,
  GUIDED_SELLING_DEAL_SUMMARY,
  GUIDED_SELLING_STAGE_TRACKER,
  INTEGRATION,
  INTEGRATIONS_SYNC_STATUS,
  INTELLIGENCE_CONTENT,
  INTERNAL_STAKEHOLDERS,
  INVOICES,
  LEAD_INSIGHTS,
  LEAD_QUALIFICATION,
  LEAD_STAGE_TRACKER,
  LEGACY_SIDEBAR_CARD,
  // This is CUSTOM
  LINKEDIN_SALES_NAVIGATOR,
  LISTS,
  MARKETING_EVENT_ABOUT,
  MARKETING_EVENT_ACTIVITY,
  MARKETING_EVENT_ATTENDANCE,
  MARKETING_EVENT_PARTICIPATION,
  MARKETING_LEAD_SCORES,
  NATIVE_INVOICES,
  NATIVE_INVOICES_HISTORY,
  NETSUITE,
  ORDER_DISCOUNT,
  ORDER_SHIPMENT_DETAIL,
  ORDER_TOTAL,
  OTHER_TICKETS,
  PARTNER_CLIENT_PRODUCT_USAGE_OUTCOMES,
  PARTNER_CLIENT_UNIFIED_USAGE_SCORE,
  PARTNER_REGISTRATION,
  PAST_CONVERSATIONS,
  PAYMENT_SCHEDULE,
  PINNED_ACTIVITY,
  PIPELINE_APPROVALS,
  PLAYBOOKS,
  PROCESS_GUIDE_LIST,
  PRODUCT_HISTORY,
  PRODUCTS,
  PRODUCT_PRICING_INFO,
  PROPERTIES,
  PROPERTIES_LIST,
  PROPERTIES_V3,
  PROPERTY_HISTORY,
  PROSPECTING_NOTES,
  PROSPECTING_RESEARCH_AGENT,
  PROTOTYPE,
  PURCHASE_DATA_WELL,
  QUALIFIED_LEADS,
  QUICK_ACTIONS,
  QUOTES,
  RECORD_TIMELINE,
  RELATIVE_TIMELINE,
  REVENUE_ATTRIBUTION,
  SALES_ACTIVITIES_TIMELINE,
  SERVICE_DELIVERY_HOURS_LOGGING,
  SFDC_SYNC,
  SHOPIFY_OVERVIEW,
  SIMPLE_DEADLINE,
  SINGLETON,
  STAGE_TRACKER,
  STATISTICS,
  SUBSCRIPTION_BILLING_INFO,
  SUBSCRIPTION_PAYMENTS_TIMELINE,
  SURVEY_MONKEY,
  TALKING_POINTS,
  TARGET_ACCOUNT,
  TARGET_ACCOUNT_BUYER_COMMITTEE,
  TARGET_ACCOUNT_DATAWELL,
  TASKS_TIMELINE,
  TOOL_LINKS,
  TRANSCRIPT,
  UNIVERSAL_TIMELINE,
  UPCOMING_ACTIVITIES,
  WEBSITE_ACTIVITY,
  WORKFLOWS,
  WORKFLOWS_INVOKER,
  ZORSE_CONVERSATIONS_TAXONOMY,
  ZORSE_MOVE_INBOX,
  ZORSE_REP_CHAT_ASSIGNMENT,
  ZORSE_TICKET_SEARCH
};