import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { registerQuery, useQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';

// To be kept in sync with https://tools.hubteamqa.com/puma/framework-app-settings/crmAssociationSettings/definition

export const fetchCrmAssociationSettingsFasAction = () => http.get('framework-builder/v1/read/metadata/types/crmAssociationSettings');
export const FETCH_CRM_ASSOCIATION_SETTINGS_FAS = registerQuery({
  fieldName: 'fetchCrmAssociationSettingsFas',
  args: [],
  fetcher: fetchCrmAssociationSettingsFasAction
});
export function useFetchCrmAssociationSettings(fullyQualifiedName) {
  const _useQuery = useQuery(FETCH_CRM_ASSOCIATION_SETTINGS_FAS, {
      fetchPolicy: 'cache-and-network'
    }),
    {
      data
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  return {
    data: data === null || data === void 0 ? void 0 : data.fetchCrmAssociationSettingsFas[fullyQualifiedName],
    rest
  };
}