import { buildAssociationTypes, fetchAssociationTypesBetweenObjectTypesQuery } from '../context/useFetchAssociationTypesData';
import { normalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { userInfoSync } from 'hub-http/userInfo';
import { UNLABELED_ASSOCIATION_BY_OBJECT_TYPE_ID_INVERSE_ID, PRIMARY_ASSOCIATION_BY_OBJECT_TYPE_ID_INVERSE_ID } from '../chicklets/associations/AssociationLabelConstants';
import { getCompleteAssociationTypeId, getAssociationsFilterGroup, getImpliedUnlabeledAssociationDetails } from '../hooks/useFetchAssociatedObjectsCardAssociations';
import { CRM_ASSOCIATION_V3_SDK_FETCH } from '../constants/GateConstants';
import isPortalSpecificObjectType from 'customer-data-objects/crmObject/isPortalSpecificObjectType';
import { FETCH_ASSOCIATED_OBJECTS_CARD_PROPERTY_DEFINITIONS, FETCH_ASSOCIATED_OBJECTS_CARD_OBJECT, FETCH_ASSOCIATED_OBJECTS_CARD_ASSOCIATIONS, FETCH_ASSOCIATED_OBJECTS_CARD_ALL_ASSOCIATION_TYPES_BETWEEN_OBJECT_IDS } from '../queries';
import { GET_ASSOCIATIONS_AUDIT_SOURCES, shouldFetchAuditSourcesForObjectTypes } from '../hooks/useFetchAssociationAuditSources';
import { getSortConfigurationDefinition } from './sortHelpers';
import { getRequiredPropertyNames } from '../constants/RequiredPropertyNames';
import { getPropertyDefinitionPropertyNames } from '../hooks/useFetchPropertyDefinitions';
const getBaseAssociationData = requestContext => {
  const {
    objectTypeId,
    objectId
  } = requestContext.additionalLocationData;
  const {
    propertyNames,
    sortConfiguration,
    toObjectTypeId: configToObjectTypeId
  } = requestContext.cardData.configuration;
  const toObjectTypeId = normalizeTypeId(configToObjectTypeId);
  const fromObjectTypeId = normalizeTypeId(objectTypeId);
  const fromObjectId = Number(objectId);
  const requiredPropertyNamesForValues = getRequiredPropertyNames(toObjectTypeId, propertyNames);
  const requiredPropertyNamesForDefinitions = getPropertyDefinitionPropertyNames({
    configPropertyNames: propertyNames,
    toObjectTypeId
  });
  const possibleImpliedUnlabeledCompleteAssociationTypeId = getCompleteAssociationTypeId({
    associationCategory: undefined,
    associationTypeId: null,
    fromObjectTypeId,
    idMap: UNLABELED_ASSOCIATION_BY_OBJECT_TYPE_ID_INVERSE_ID,
    toObjectTypeId
  });
  const shouldFetchAssociationTypesFirst = isPortalSpecificObjectType(toObjectTypeId) || isPortalSpecificObjectType(fromObjectTypeId) || possibleImpliedUnlabeledCompleteAssociationTypeId === null;
  return {
    fromObjectId,
    fromObjectTypeId,
    objectTypeId,
    possibleImpliedUnlabeledCompleteAssociationTypeId,
    requiredPropertyNamesForDefinitions,
    requiredPropertyNamesForValues,
    shouldFetchAssociationTypesFirst,
    sortConfiguration,
    toObjectTypeId
  };
};
const getAssociationTypesBetweenIdsPromise = (clients, {
  fromObjectId,
  fromObjectTypeId,
  toObjectTypeId,
  associationsResult
}) => {
  var _associationsResult$d;
  const toObjectIds = (associationsResult === null || associationsResult === void 0 || (_associationsResult$d = associationsResult.data) === null || _associationsResult$d === void 0 || (_associationsResult$d = _associationsResult$d.impliedUnlabeledAssociationObjects) === null || _associationsResult$d === void 0 || (_associationsResult$d = _associationsResult$d.results) === null || _associationsResult$d === void 0 ? void 0 : _associationsResult$d.map(association => association.id)) || [];
  const shouldFetchAssociationTypesBetweenIds = toObjectIds.length > 0;
  if (!shouldFetchAssociationTypesBetweenIds) {
    return Promise.resolve(undefined);
  }
  return clients.graphqlClient.query({
    query: FETCH_ASSOCIATED_OBJECTS_CARD_ALL_ASSOCIATION_TYPES_BETWEEN_OBJECT_IDS,
    variables: {
      fromObjectId,
      fromObjectType: fromObjectTypeId,
      toObjectIds,
      toObjectType: toObjectTypeId
    },
    context: {
      noBatch: true
    }
  });
};
const getAuditSourcesPromise = (clients, {
  associationsResult,
  fromObjectId,
  fromObjectTypeId,
  impliedUnlabeledAssociationCategory,
  impliedUnlabeledAssociationTypeId,
  toObjectTypeId
}) => {
  var _associationsResult$d2, _associationsResult$d3;
  const toObjectIds = (associationsResult === null || associationsResult === void 0 || (_associationsResult$d2 = associationsResult.data) === null || _associationsResult$d2 === void 0 || (_associationsResult$d2 = _associationsResult$d2.impliedUnlabeledAssociationObjects) === null || _associationsResult$d2 === void 0 || (_associationsResult$d2 = _associationsResult$d2.results) === null || _associationsResult$d2 === void 0 ? void 0 : _associationsResult$d2.map(association => association.id)) || [];
  const shouldFetchAuditSources = toObjectIds.length > 0 && shouldFetchAuditSourcesForObjectTypes(fromObjectTypeId, toObjectTypeId) && impliedUnlabeledAssociationCategory !== null && impliedUnlabeledAssociationTypeId !== null;
  if (!shouldFetchAuditSources) {
    return Promise.resolve(undefined);
  }
  const associationSpecs = (_associationsResult$d3 = associationsResult.data) === null || _associationsResult$d3 === void 0 || (_associationsResult$d3 = _associationsResult$d3.impliedUnlabeledAssociationObjects) === null || _associationsResult$d3 === void 0 ? void 0 : _associationsResult$d3.results.map(association => ({
    objectId: association.id,
    associationSpec: {
      associationCategory: impliedUnlabeledAssociationCategory,
      associationTypeId: impliedUnlabeledAssociationTypeId
    }
  }));
  return clients.dfcClient.query({
    query: GET_ASSOCIATIONS_AUDIT_SOURCES,
    variables: {
      associationSpecs,
      fromObjectId
    },
    context: {
      noBatch: true
    }
  });
};
const createPropertyDefinitionAndObjectPromises = (clients, {
  toObjectTypeId,
  fromObjectId,
  objectTypeId,
  requiredPropertyNamesForDefinitions
}) => [clients.graphqlClient.query({
  query: FETCH_ASSOCIATED_OBJECTS_CARD_PROPERTY_DEFINITIONS,
  variables: {
    toObjectTypeId,
    properties: requiredPropertyNamesForDefinitions
  },
  context: {
    noBatch: true
  }
}), clients.graphqlClient.query({
  query: FETCH_ASSOCIATED_OBJECTS_CARD_OBJECT,
  variables: {
    objectId: fromObjectId,
    objectType: objectTypeId,
    toObjectTypeId
  },
  context: {
    noBatch: true
  }
})];

// This function primes the data in the cache for the card, we do not reference the returned value
export function fetchAssociatedObjectsCardData(clients, requestContext) {
  const userInfo = userInfoSync();
  if (!userInfo.gates.includes(CRM_ASSOCIATION_V3_SDK_FETCH)) {
    return Promise.resolve(undefined);
  }
  const {
    toObjectTypeId,
    fromObjectTypeId,
    fromObjectId,
    objectTypeId,
    requiredPropertyNamesForValues,
    requiredPropertyNamesForDefinitions,
    sortConfiguration,
    shouldFetchAssociationTypesFirst,
    possibleImpliedUnlabeledCompleteAssociationTypeId
  } = getBaseAssociationData(requestContext);
  const independentPromises = createPropertyDefinitionAndObjectPromises(clients, {
    toObjectTypeId,
    fromObjectId,
    objectTypeId: objectTypeId,
    requiredPropertyNamesForDefinitions
  });
  const associationTypesPromise = clients.graphqlClient.query({
    query: fetchAssociationTypesBetweenObjectTypesQuery,
    variables: {
      fromObjectTypeId,
      toObjectTypeId
    },
    context: {
      noBatch: true
    }
  });
  if (shouldFetchAssociationTypesFirst) {
    return Promise.allSettled([...independentPromises, associationTypesPromise.then(associationTypesResult => {
      var _associationTypesResu;
      const {
        associationTypes,
        unlabeledAssociationType,
        primaryAssociationType
      } = buildAssociationTypes(((_associationTypesResu = associationTypesResult.data) === null || _associationTypesResu === void 0 ? void 0 : _associationTypesResu.allAssociationTypesBetweenObjectTypes) || [], associationTypesResult.error, associationTypesResult.loading);
      const {
        impliedUnlabeledAssociationCategory,
        impliedUnlabeledAssociationTypeId,
        impliedUnlabeledInverseAssociationTypeId
      } = getImpliedUnlabeledAssociationDetails({
        associationTypes,
        unlabeledAssociationType
      });
      const primaryAssociationCategory = primaryAssociationType && primaryAssociationType.associationCategory;
      const primaryInverseAssociationTypeId = primaryAssociationType ? primaryAssociationType.inverseAssociationTypeId : null;
      const impliedUnlabeledCompleteAssociationTypeId = getCompleteAssociationTypeId({
        associationCategory: impliedUnlabeledAssociationCategory,
        associationTypeId: impliedUnlabeledInverseAssociationTypeId,
        fromObjectTypeId,
        idMap: UNLABELED_ASSOCIATION_BY_OBJECT_TYPE_ID_INVERSE_ID,
        toObjectTypeId
      });
      const primaryCompleteAssociationTypeId = getCompleteAssociationTypeId({
        associationCategory: primaryAssociationCategory,
        associationTypeId: primaryInverseAssociationTypeId || null,
        fromObjectTypeId,
        idMap: PRIMARY_ASSOCIATION_BY_OBJECT_TYPE_ID_INVERSE_ID,
        toObjectTypeId
      });
      const unlabeledAssociationFilterGroups = getAssociationsFilterGroup({
        completeAssociationTypeId: impliedUnlabeledCompleteAssociationTypeId,
        fromObjectId
      });
      const primaryAssociationFilterGroups = getAssociationsFilterGroup({
        completeAssociationTypeId: primaryCompleteAssociationTypeId,
        fromObjectId
      });
      return clients.graphqlClient.query({
        query: FETCH_ASSOCIATED_OBJECTS_CARD_ASSOCIATIONS,
        variables: {
          unlabeledAssociationFilterGroups,
          hasPrimaryAssociation: Boolean(primaryCompleteAssociationTypeId),
          offset: 0,
          pageSize: 5,
          primaryAssociationFilterGroups,
          sorts: getSortConfigurationDefinition({
            sortConfiguration
          }),
          toObjectTypeId,
          propertyNames: requiredPropertyNamesForValues
        },
        context: {
          noBatch: true
        }
      }).then(associationsResult => Promise.allSettled([getAssociationTypesBetweenIdsPromise(clients, {
        associationsResult,
        fromObjectId,
        fromObjectTypeId,
        toObjectTypeId
      }), getAuditSourcesPromise(clients, {
        associationsResult,
        fromObjectId,
        fromObjectTypeId,
        impliedUnlabeledAssociationCategory,
        impliedUnlabeledAssociationTypeId,
        toObjectTypeId
      })]));
    })]).catch(e => {
      console.error(e);
      return Promise.resolve(undefined);
    });
  }
  const possiblePrimaryCompleteAssociationTypeId = getCompleteAssociationTypeId({
    associationCategory: undefined,
    associationTypeId: null,
    fromObjectTypeId,
    idMap: PRIMARY_ASSOCIATION_BY_OBJECT_TYPE_ID_INVERSE_ID,
    toObjectTypeId
  });
  const unlabeledAssociationFilterGroups = getAssociationsFilterGroup({
    completeAssociationTypeId: possibleImpliedUnlabeledCompleteAssociationTypeId,
    fromObjectId
  });
  const primaryAssociationFilterGroups = getAssociationsFilterGroup({
    completeAssociationTypeId: possiblePrimaryCompleteAssociationTypeId,
    fromObjectId
  });
  return Promise.allSettled([...independentPromises, associationTypesPromise, clients.graphqlClient.query({
    query: FETCH_ASSOCIATED_OBJECTS_CARD_ASSOCIATIONS,
    variables: {
      unlabeledAssociationFilterGroups,
      hasPrimaryAssociation: Boolean(possiblePrimaryCompleteAssociationTypeId),
      offset: 0,
      pageSize: 5,
      primaryAssociationFilterGroups,
      sorts: getSortConfigurationDefinition({
        sortConfiguration
      }),
      toObjectTypeId,
      propertyNames: requiredPropertyNamesForValues
    },
    context: {
      noBatch: true
    }
  }).then(associationsResult => getAssociationTypesBetweenIdsPromise(clients, {
    associationsResult,
    fromObjectId,
    fromObjectTypeId,
    toObjectTypeId
  }))]).catch(e => {
    console.error(e);
    return Promise.resolve(undefined);
  });
}