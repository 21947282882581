const SORT_LOOKUP = {
  dealstage: 'dealstage.displayOrder',
  hs_priority: 'hs_priority.displayOrder',
  hs_ticket_priority: 'hs_ticket_priority.displayOrder',
  hs_pipeline_stage: 'hs_pipeline_stage.displayOrder'
};
const DEFAULT_SORT = [Object.freeze({
  property: 'hs_object_id',
  order: 'DESC'
})];

// We want to sort by display order for enumeration properties, which means
// "extending" the property to be property.displayOrder.
const extendPropertyName = propertyName => {
  return SORT_LOOKUP[propertyName] || propertyName;
};
export const getSortConfigurationDefinition = ({
  sortConfiguration
}) => {
  if (!sortConfiguration || sortConfiguration.length === 0) {
    return DEFAULT_SORT;
  }
  return sortConfiguration.map(sortConfig => {
    return {
      property: extendPropertyName(sortConfig.property),
      order: sortConfig.direction
    };
  });
};