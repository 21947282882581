export let EnrollmentFlowType;
(function (EnrollmentFlowType) {
  EnrollmentFlowType["processorPicker"] = "processorPicker";
  EnrollmentFlowType["native"] = "native";
  EnrollmentFlowType["stripe"] = "stripe";
  EnrollmentFlowType["select"] = "select";
})(EnrollmentFlowType || (EnrollmentFlowType = {}));
export let CancelSurvey;
(function (CancelSurvey) {
  CancelSurvey["NONE"] = "NONE";
  CancelSurvey["NATIVE"] = "NATIVE";
  CancelSurvey["PROCESSOR_PICKER"] = "PROCESSOR_PICKER";
  CancelSurvey["SELECT"] = "SELECT";
})(CancelSurvey || (CancelSurvey = {}));