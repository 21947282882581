export const scopes = {
  BILLING_ACCESS: 'billing-access',
  BREEZE_INTELLIGENCE_FREE: 'breeze-intelligence-free',
  CONVERSATIONAL_ENRICHMENT_BASIC: 'conversational-enrichment-basic-access',
  CONVERSATIONAL_ENRICHMENT_FREE: 'conversational-enrichment-free-access',
  CONVERSATIONAL_ENRICHMENT_PREMIUM: 'conversational-enrichment-premium-access',
  DATA_ENRICHMENT_ACCESS: 'data-enrichment-access',
  ENRICHMENT_CONTINUOUS_ACCESS: 'data-enrichment-continuous-access',
  ENRICHMENT_ONE_TIME_USAGE: 'data-enrichment-one-time-usage',
  ENRICHMENT_PLATFORM_ACCESS: 'data-enrichment-platform-access',
  ENRICHMENT_UNLIMITED_USAGE: 'data-enrichment-unlimited-usage',
  MANAGE_DATA_ENRICHMENT: 'manage-data-enrichment',
  SUPER_ADMIN: 'super-admin'
};