import { useEffect } from 'react';
import { useMessageBus } from './useMessageBus';
export function useMessageRelay(relay) {
  const messageBus = useMessageBus();
  useEffect(() => {
    if (!messageBus) {
      return () => {};
    }
    const unbindRelay = messageBus.bindRelay(relay);
    return () => {
      unbindRelay();
    };
  }, [messageBus, relay]);
}