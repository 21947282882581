import enviro from 'enviro';
import http from 'hub-http/clients/apiClient';
import { hubletApi } from 'hub-http/middlewares/core';
import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { captureNetworkError } from 'growth-onboarding-reliability/utils/raven';
export function fetchCsatSurvey(surveyType, surveyId) {
  return http.get(`feedback/v1/survey-config/${surveyType}/${surveyId}`)
  // Report error to sentry and not re-throw error so that the upstream pages are not broken and the only effect is that the survey is not displayed.
  .catch(captureNetworkError);
}
export function submitCsatSurvey({
  surveyId,
  contactId,
  rating,
  followUp
}) {
  const isQa = enviro.isQa();
  const portalId = isQa ? 100765651 : 53;
  const webAnalytics = {
    pageTitle: document.title,
    pageUrl: window.location.href,
    userAgent: window.navigator.userAgent
  };
  const feedbackApi = hubletApi('feedback', 'hubapi');
  return noAuthApiClient.post('feedback/public/v1/submit/csat', {
    api: feedbackApi,
    data: {
      channel: 'WEB',
      contactId,
      portalId,
      surveyChannel: 'WEB',
      surveyId,
      value: rating,
      webAnalytics
    }
  }).then(({
    submissionId
  }) => noAuthApiClient.post('feedback/public/v1/submission-follow-up/csat', {
    api: feedbackApi,
    data: {
      followUp,
      portalId,
      rating,
      submissionId,
      surveyId,
      surveyType: 'CSAT'
    }
  })).catch(captureNetworkError);
}