import { registerQuery } from 'data-fetching-client';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
const fetchCrmPageEditorAppSettings = ({
  objectTypeId
}) => {
  return getFrameworkDataSchemasClient().then(client => client.typeMetadata.get({
    appSettingNames: ['crmPageEditor']
  })).then(settings => {
    var _Object$values$find;
    return (_Object$values$find = Object.values(settings).find(setting => {
      var _setting$crmPageEdito;
      return (setting === null || setting === void 0 || (_setting$crmPageEdito = setting.crmPageEditor) === null || _setting$crmPageEdito === void 0 || (_setting$crmPageEdito = _setting$crmPageEdito.metadata) === null || _setting$crmPageEdito === void 0 || (_setting$crmPageEdito = _setting$crmPageEdito.objectTypeId) === null || _setting$crmPageEdito === void 0 ? void 0 : _setting$crmPageEdito.value) === objectTypeId;
    })) === null || _Object$values$find === void 0 ? void 0 : _Object$values$find.crmPageEditor;
  });
};
export const CRM_PAGE_EDITOR_APP_SETTINGS = registerQuery({
  fieldName: 'crmPageEditorAppSettings',
  args: ['objectTypeId'],
  fetcher: fetchCrmPageEditorAppSettings
});