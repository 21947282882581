import { useCallback, useEffect, useState } from 'react';
import { PaymentsEnrollmentIframeMessageType } from '../constants/messages';
import { CancelSurvey } from '../types';
import { navigateTo } from 'growth-payments-core/urls/utils';
import { useQueryStringState } from 'growth-payments-core/query-string/useQueryStringState';
import { events } from 'growth-payments-core/usage-tracker/events';
import { track } from 'growth-payments-core/usage-tracker/track';
import { URL_LOAD_LOCATION } from '../constants/urls';
export const usePaymentsEnrollmentUtilities = (enrollmentType, sourceApp, location, onPaymentsEnrollmentSuccess) => {
  const [currentEnrollmentFlow, setCurrentEnrollmentFlow] = useQueryStringState('paymentsEnrollment', undefined);
  const [enrollmentFlowLocation, setEnrollmentFlowLocation] = useState(null);
  const [showNativeSuccessModal, setShowNativeSuccessModal] = useState(false);
  const [showNativeFallbackModal, setShowNativeFallbackModal] = useState(false);
  const [showEmbeddedOnboarding, setShowEmbeddedOnboarding] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showOAuthOnboardingSuccessModal, setShowOAuthOnboardingSuccessModal] = useState(false);
  const [cancelSurveyType, setCancelSurveyType] = useState(CancelSurvey.NONE);

  // Handles case where enrollment flow is loaded directly from the URL.
  // There may be multiple buttons on the page, each with their own location. Instead,
  // we set a generic `URL_LOAD_LOCATION` to maintain that distinction.
  useEffect(() => {
    if (currentEnrollmentFlow && !enrollmentFlowLocation) {
      setEnrollmentFlowLocation(URL_LOAD_LOCATION);
    }
    // We only want to do this on page load, hence, an empty dep array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [businessTypeDetails, setBusinessTypeDetails] = useState({
    businessType: null,
    businessStructure: null
  });
  const [isLoading, setIsLoading] = useState(true);
  const openEnrollmentFlow = useCallback(() => {
    track(events.paymentSettingsInteraction, {
      action: 'goToPaymentsEnrollmentClicked',
      page: sourceApp,
      location
    });
    setEnrollmentFlowLocation(location);
    setCurrentEnrollmentFlow(enrollmentType);
  }, [enrollmentType, setCurrentEnrollmentFlow, sourceApp, location]);
  const handlePaymentsEnrollmentClose = useCallback(() => {
    setCurrentEnrollmentFlow(undefined);
    setEnrollmentFlowLocation(null);
  }, [setCurrentEnrollmentFlow]);
  const handleIframeReady = useCallback(() => {
    setIsLoading(false);
  }, []);
  const handleCloseEmbeddedOnboarding = useCallback(() => {
    setShowEmbeddedOnboarding(false);
  }, []);
  const handleCloseCancelModal = useCallback(cancelSurveyToShow => {
    if (cancelSurveyToShow) {
      setCancelSurveyType(cancelSurveyToShow);
    }
    setShowCancelModal(false);
  }, []);
  const handleIframeOnMessage = useCallback(({
    payload
  }) => {
    switch (payload.type) {
      case PaymentsEnrollmentIframeMessageType.SHOW_FALLBACK_MODAL:
        handlePaymentsEnrollmentClose();
        setShowNativeFallbackModal(true);
        setBusinessTypeDetails(payload.data);
        onPaymentsEnrollmentSuccess === null || onPaymentsEnrollmentSuccess === void 0 || onPaymentsEnrollmentSuccess();
        return;
      case PaymentsEnrollmentIframeMessageType.SHOW_SUCCESS_MODAL:
        handlePaymentsEnrollmentClose();
        setShowNativeSuccessModal(true);
        setBusinessTypeDetails(payload.data);
        onPaymentsEnrollmentSuccess === null || onPaymentsEnrollmentSuccess === void 0 || onPaymentsEnrollmentSuccess();
        return;
      case PaymentsEnrollmentIframeMessageType.SHOW_CANCEL_MODAL:
        setShowCancelModal(true);
        return;
      case PaymentsEnrollmentIframeMessageType.SHOW_CANCEL_SURVEY:
        handlePaymentsEnrollmentClose();
        setCancelSurveyType(payload.cancelSurveyType);
        return;
      case PaymentsEnrollmentIframeMessageType.REDIRECT:
        navigateTo(payload.link, payload.openInNewWindow);
        return;
      case PaymentsEnrollmentIframeMessageType.SHOW_OAUTH_ONBOARDING_SUCCESS_MODAL:
        setShowOAuthOnboardingSuccessModal(true);
        onPaymentsEnrollmentSuccess === null || onPaymentsEnrollmentSuccess === void 0 || onPaymentsEnrollmentSuccess();
        return;
      case PaymentsEnrollmentIframeMessageType.CLOSE_EMBEDDED_ONBOADING:
        handleCloseEmbeddedOnboarding();
        return;
      default:
        break;
    }
  }, [setBusinessTypeDetails, handlePaymentsEnrollmentClose, onPaymentsEnrollmentSuccess, handleCloseEmbeddedOnboarding]);
  return {
    currentEnrollmentFlow,
    enrollmentFlowLocation,
    setCurrentEnrollmentFlow,
    openEnrollmentFlow,
    handleIframeReady,
    handleIframeOnMessage,
    isLoading,
    handlePaymentsEnrollmentClose,
    showNativeSuccessModal,
    setShowNativeSuccessModal,
    setShowNativeFallbackModal,
    showEmbeddedOnboarding,
    setShowEmbeddedOnboarding,
    showNativeFallbackModal,
    showCancelModal,
    showOAuthOnboardingSuccessModal,
    handleCloseCancelModal,
    cancelSurveyType,
    setCancelSurveyType,
    setShowOAuthOnboardingSuccessModal,
    businessTypeDetails,
    sourceApp,
    location
  };
};