import { useEffect, useState } from 'react';
/**
 * Ported from `useLocalStorageState` https://sourcegraph.hubteam.com/git.hubteam.com/HubSpot/crm-components@597f6064c6d27f3ee9b6a29116cc87bc82dad4c4/-/blob/crm-component-utils/static-1.30686/js/hooks/useLocalStorageState.ts
 * A wrapper around `useState` that also interfaces with `localStorage`. It returns a tuple of [stateValue, stateSetter] exactly as `useState` does, but with the added benefits of using any previously stored value as the initial state value, as well as updating the stored value any time the state is updated.
 *
 * There is one important limitation: because `localStorage` requires all values to be stored as strings, we serialize all values via `JSON.stringify` before storing (and likewise deserialize the stored value with `JSON.parse`). This means this hook should only be used with values that can be JSON-stringified on their own. Of note, this excludes functions, `undefined`, and many built-ins such as `Symbol`, `Map`, and `Set` (these are examples, not an exhaustive list).
 *
 * If a value provided is of an unsupported type, its serialization (and any subsequent deserialization) will most likely not work as expected, and should therefore be avoided. You can refer to [the MDN docs](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#description) for a more thorough explanation.
 */
export const useLocalStorageState = ({
  key,
  initialValue
}) => {
  const [storedItem, setStoredItem] = useState(() => {
    try {
      if ((key === null || key === void 0 ? void 0 : key.length) === 0) {
        return initialValue;
      }
      const storageValue = localStorage.getItem(key);
      return storageValue ? JSON.parse(storageValue) : initialValue;
    } catch (err) {
      console.warn('[useLocalStorageState] An error occurred while retrieving initial stored value for key ', key, err);
      return initialValue;
    }
  });
  useEffect(() => {
    try {
      if ((key === null || key === void 0 ? void 0 : key.length) > 0) {
        localStorage.setItem(key, JSON.stringify(storedItem));
      }
    } catch (err) {
      console.error('[useLocalStorageState] An error occurred while trying to update the stored value for key ', key, err);
    }
  }, [key, storedItem]);
  return [storedItem, setStoredItem];
};