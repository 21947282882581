import { useTheme } from './useTheme';
const borderRadiusMap = {
  sm: '0.125rem',
  default: '3px',
  md: '0.25rem',
  lg: '0.5rem',
  xl: '1rem',
  xxl: '2rem',
  full: '100%'
};
export const useTokens = () => {
  const {
    borderRadius,
    borderWidth,
    boxShadow,
    breezeColors,
    color,
    space
  } = useTheme();
  const tokens = {
    backgroundColor: {
      default: color['fill-surface-raised'],
      dark: color['text-core-default'],
      light: color['fill-surface-default']
    },
    borderColor: {
      default: color['border-core-subtle'],
      dark: color['border-core-default'],
      light: color['border-core-pressed']
    },
    borderWidth: {
      default: borderWidth[100],
      double: borderWidth[200]
    },
    borderRadius: {
      sm: borderRadiusMap.sm,
      default: borderRadius[100],
      md: borderRadiusMap.md,
      lg: borderRadiusMap.lg,
      xl: borderRadiusMap.xl,
      xxl: borderRadiusMap.xxl,
      full: borderRadiusMap.full
    },
    breezeBrand: {
      default: breezeColors.primary.default,
      dark: breezeColors.primary.dark,
      light: breezeColors.primary.light,
      extraLight: breezeColors.primary.extraLight,
      extraExtraLight: breezeColors.primary['2xLight'],
      gradient: {
        dark: breezeColors.gradient.dark,
        light: breezeColors.gradient.light,
        medium: breezeColors.gradient.medium
      }
    },
    shadow: {
      card: boxShadow[100]
    },
    space: {
      xxxxs: space[25],
      xxxs: space[50],
      xxs: space[100],
      xs: space[200],
      sm: space[300],
      default: space[400],
      md: space[600],
      lg: space[800],
      xl: space[1000],
      xxl: space[1100],
      xxxl: space[1400],
      xxxxl: space[1500]
    },
    textColor: {
      base: color['text-core-default'],
      danger: color['text-alert-default'],
      disabled: color['text-core-disabled'],
      extraLight: color['text-primary-subtle'],
      light: color['text-primary-subtle'],
      inverse: color['text-primary-on-fill-default']
    }
  };
  return {
    tokens
  };
};