import { useCallback } from 'react';
import { useMessageBus } from './useMessageBus';
import { useCrmMessagingContext } from './CrmMessagingContext';
export function useSendCrmMessage() {
  const messageBus = useMessageBus();
  const messagingContext = useCrmMessagingContext();
  return useCallback((topic, data, config = {}) => {
    if (!messageBus) {
      return;
    }
    let enveloperSourceId;
    if (config.sourceId) {
      enveloperSourceId = config.sourceId;
    } else if (messagingContext && messagingContext.messagingId) {
      enveloperSourceId = messagingContext.messagingId;
    }
    const envelope = {
      sourceId: enveloperSourceId,
      targetId: config.targetId
    };
    const envelopeData = {
      envelope,
      data
    };
    messageBus.publish(topic, envelopeData);
  }, [messageBus, messagingContext]);
}