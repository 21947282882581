import { SkeletonText } from './SkeletonText';
import styled from 'styled-components';
import { GYPSUM, KOALA } from 'HubStyleTokens/colors';
import UIIcon from 'UIComponents/icon/UIIcon';
export const ShinySkeletonText = styled(SkeletonText).withConfig({
  displayName: "skeletonHelpers__ShinySkeletonText",
  componentId: "dz097b-0"
})(["background:", ";background:linear-gradient(to right,", ",", " 20%,", " 40%);background-size:1200px;"], GYPSUM, GYPSUM, KOALA, GYPSUM);
export const ShinySkeletonTextBody = styled(SkeletonText).withConfig({
  displayName: "skeletonHelpers__ShinySkeletonTextBody",
  componentId: "dz097b-1"
})(["background:", ";background:linear-gradient(to right,", ",", " 20%,", " 40%);background-size:1200px;width:", ";"], GYPSUM, GYPSUM, KOALA, GYPSUM, ({
  width
}) => width);
export const ShinySkeletonCircle = styled.div.withConfig({
  displayName: "skeletonHelpers__ShinySkeletonCircle",
  componentId: "dz097b-2"
})(["background:", ";background:linear-gradient(to right,", ",", " 20%,", " 40%);background-size:1200px;background-color:", ";border-radius:50%;height:", "px;width:", "px;"], GYPSUM, GYPSUM, KOALA, GYPSUM, KOALA, ({
  size
}) => size, ({
  size
}) => size);
export const SkeletonIcon = styled(UIIcon).withConfig({
  displayName: "skeletonHelpers__SkeletonIcon",
  componentId: "dz097b-3"
})(["color:", ";margin-left:-2px;"], GYPSUM);