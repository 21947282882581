import { gate } from 'hub-http/gates';
export const gates = {
  CONVERSATIONAL_ENRICHMENT_BETA: gate('ConversationalEnrichment:EmailParser:Beta'),
  CONVERSATIONAL_ENRICHMENT_EMPLOYMENT_CHANGE: gate('ConversationalEnrichment:Model:EmploymentChange'),
  CONVERSATIONAL_ENRICHMENT_OUT_OF_OFFICE: gate('ConversationalEnrichment:Model:OutOfOffice'),
  ENRICHMENT_ASSOCIATIONS: gate('DataEnrichment:Associations'),
  ENRICHMENT_ASSOCIATIONS_LISTS: gate('DataEnrichment:AssociationsLists'),
  ENRICHMENT_AUTO_LAST_ENGAGEMENT_CONTACTS: gate('DataEnrichment:LastEngagementContacts'),
  ENRICHMENT_AUTO_LAST_ENGAGEMENT_COMPANIES: gate('DataEnrichment:LastEngagementCompanies'),
  ENRICHMENT_AUTO_SETTING_NUDGE: gate('DataEnrichment:AutoSettingNudge'),
  ENRICHMENT_SMART_PROPERTIES: gate('DataEnrichment:SmartProperties')
};