/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { useMutation } from 'data-fetching-client';
import { useCallback } from 'react';
import { CREATE_ONBOARDING_LINK } from '../queries/onBoardingLinkQueries';
import { addDangerAlert } from 'growth-payments-core/alerts';
const useOnboardingRedirect = () => {
  const [createOnboardingLink, {
    loading,
    error
  }] = useMutation(
  // @ts-expect-error FIXME: update code for `TypedDocumentNode` changes
  CREATE_ONBOARDING_LINK, {
    onCompleted: ({
      onBoardingLink
    }) => {
      const onboardingLocation = onBoardingLink.url;
      window.location.href = onboardingLocation;
    },
    onError: () => {
      addDangerAlert({
        title: 'errorAlert.onboardingLinkCreationError'
      });
    }
  });
  const redirect = useCallback(() => {
    createOnboardingLink({
      variables: {
        type: 'VERIFICATION'
      }
    });
  }, [createOnboardingLink]);
  return {
    loading,
    error,
    redirect
  };
};
export default useOnboardingRedirect;