import { Loadable } from 'foundations-components/transitional/utils/Loadable';
import Raven from 'raven-js';
function ErrorComponent({
  error
}) {
  Raven.captureException(error);
  return null;
}
export default Loadable({
  loader: () => import( /* webpackChunkName: "SharePaymentLinkModal" */'./SharePaymentLinkModal').then(mod => mod.default),
  ErrorComponent
});