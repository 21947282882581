import CardLoadingState from 'crm-cards-sdk/components/CardLoadingState';
import { DEFAULT_LOADING_COMPONENT_PROPS } from 'crm-cards-sdk/constants/LoadingComponentProps';
import { memoizeRegisterTranslations } from './internal/memoizeRegisterTranslations';
import { CRM_CARDS_GLOBAL_REGISTRY_MAP } from 'crm-cards-global-registry/CrmCardsGlobalRegistry';
import { Loadable } from 'foundations-components/transitional/utils/Loadable';
import memoize from 'react-utils/memoize';
import mark from 'crm-fe-perf/timing/mark';
import measure from 'crm-fe-perf/timing/measure';
import { getDownloadStart, getDownloadFinish } from 'crm-fe-perf/timing/marks/crm-cards-sdk/performanceMarks';
import { getDownloadMeasure } from 'crm-fe-perf/timing/measurements/crm-cards-sdk/performanceMeasurements';
import { getWasHidden } from 'crm-cards-sdk/utils/sharedPerfDimensions';
function isAsyncCrmCardRegistryEntry(registryEntry) {
  return !!registryEntry.cardComponentLoader;
}
const loaderCache = new Map();
function wrapWithMarks(cardType, loader, dimensions) {
  const key = cardType;
  if (loaderCache.has(key)) {
    return loaderCache.get(key).loader;
  } else {
    const cacheEntry = {
      // @ts-expect-error This is a placeholder, loader is assigned before return
      loader: undefined,
      startMarkFired: false,
      endMarkFired: false
    };
    const updatedDim = Object.assign({}, dimensions, {
      card_type: cardType,
      part: 'component'
    });
    const loaderWithMarks = () => new Promise((resolve, reject) => {
      if (!cacheEntry.startMarkFired) {
        cacheEntry.startMarkFired = true;
        mark(getDownloadStart(cardType, 'component'), updatedDim, true);
      }
      loader().then(result => {
        if (!cacheEntry.endMarkFired) {
          cacheEntry.endMarkFired = true;
          mark(getDownloadFinish(cardType, 'component'), updatedDim, true);
          measure(getDownloadMeasure(cardType, 'component'), Object.assign({}, updatedDim, {
            start: '',
            // hardcode this to reduce cardinality
            end: '',
            // hardcode this to reduce cardinality
            was_hidden: getWasHidden()
          }));
        }
        resolve(result);
      }, reject).catch(reject);
    });
    cacheEntry.loader = loaderWithMarks;
    loaderCache.set(key, cacheEntry);
    return cacheEntry.loader;
  }
}
export function buildGlobalCrmCardRegistry(location, loggingTag) {
  const registry = buildCrmCardRegistry(CRM_CARDS_GLOBAL_REGISTRY_MAP, location, loggingTag);
  // @ts-expect-error This is a private property that is only used in crm-card-infra-frontend
  registry._unsafeIsGlobalRegistry = true;
  return registry;
}
export function buildCrmCardRegistry(crmCardRegistryMap = CRM_CARDS_GLOBAL_REGISTRY_MAP, location, loggingTag) {
  const dimensions = {
    location: location || '',
    logging_tag: loggingTag || ''
  };
  function preloadCardModules(cardType, i18nLoader) {
    const cardRegistryEntry = crmCardRegistryMap[cardType];
    if (!cardRegistryEntry) {
      return;
    }
    if (isAsyncCrmCardRegistryEntry(cardRegistryEntry)) {
      wrapWithMarks(cardType, cardRegistryEntry.cardComponentLoader, dimensions)().catch(() => {
        // Do nothing, this is just a preloader, errors should be handled in the CrmCardWrapper
      });
    }
    if (cardRegistryEntry.lazyLoadedTranslations) {
      memoizeRegisterTranslations({
        i18nLoader,
        lazyLoadedTranslations: cardRegistryEntry.lazyLoadedTranslations
      }).catch(() => {
        // Do nothing, this is just a preloader, errors should be handled in the CardTranslationsLoader
      });
    }
  }
  function getCardComponent(cardType) {
    const cardRegistryEntry = crmCardRegistryMap[cardType];
    if (!cardRegistryEntry) {
      throw new Error(`${cardType} not defined in card registry`);
    }
    if (isAsyncCrmCardRegistryEntry(cardRegistryEntry)) {
      return Loadable({
        loader: wrapWithMarks(cardType, cardRegistryEntry.cardComponentLoader, dimensions),
        LoadingComponent: CardLoadingState
      });
    }
    return cardRegistryEntry.CardComponent;
  }
  function cardDataResolver(clients, requestContext) {
    const cardRegistryEntry = crmCardRegistryMap[requestContext.cardData.definition.cardType];
    if (cardRegistryEntry && cardRegistryEntry.dataResolver) {
      return cardRegistryEntry.dataResolver(clients, requestContext);
    }
  }

  /**
   * This is memoized to avoid getCardComponent returning a new instance of the Loadable component when cardData changes.
   */
  const memoizedGetCardComponent = memoize(
  // @ts-expect-error memoize is really hard to use when memoizing functions that take generics
  getCardComponent);
  function getLazyLoadedTranslations(cardType) {
    const cardRegistryEntry = crmCardRegistryMap[cardType];
    if (!cardRegistryEntry) {
      throw new Error(`${cardType} not defined in card registry`);
    }
    return cardRegistryEntry.lazyLoadedTranslations;
  }
  function getLoadingComponentProps(cardType, cardData, cardLocationContext) {
    const cardRegistryEntry = crmCardRegistryMap[cardType];
    if (!cardRegistryEntry) {
      throw new Error(`${cardType} not defined in card registry`);
    }
    if (cardRegistryEntry.getLoadingComponentProps) {
      return cardRegistryEntry.getLoadingComponentProps(cardData, cardLocationContext);
    }
    if (cardRegistryEntry.loadingComponentProps) {
      return cardRegistryEntry.loadingComponentProps;
    }
    return DEFAULT_LOADING_COMPONENT_PROPS;
  }
  return {
    getCardComponent: memoizedGetCardComponent,
    getLazyLoadedTranslations,
    getLoadingComponentProps,
    preloadCardModules,
    cardDataResolver
  };
}