module.exports = {
  "subscriptionInteraction": {
    "name": "commerce-billing",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "buttonType": {
        "type": [
          "BILLING_WIZARD",
          "PAYMENT_LINK"
        ],
        "isOptional": true
      },
      "isEnrolled": {
        "type": "boolean",
        "isOptional": true
      },
      "isActive": {
        "type": "boolean",
        "isOptional": true
      },
      "processorType": {
        "type": [
          "BYO_STRIPE",
          "HS_PAYMENTS",
          "NONE"
        ],
        "isOptional": true
      }
    },
    "namespace": "commerce-billing",
    "version": "1"
  },
  "interactionEvent": {
    "namespace": "BILLING_WIZARD",
    "name": "BILLING_WIZARD",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "isEnrolled": {
        "type": "boolean",
        "isOptional": true
      },
      "isActive": {
        "type": "boolean",
        "isOptional": true
      },
      "processorType": {
        "type": [
          "BYO_STRIPE",
          "HS_PAYMENTS",
          "NONE"
        ],
        "isOptional": true
      }
    },
    "version": "1"
  }
};