var _document;
import promiseClient from 'hub-http/adapters/promiseClient';
import { createStack } from 'hub-http';
import HubapiStack from 'hub-http/stacks/hubapi';
// @ts-expect-error hub-http doesn't have declared types
import { set } from 'hub-http/helpers/update';
const isAcceptanceTest = !!((_document = document) !== null && _document !== void 0 && (_document = _document.cookie) !== null && _document !== void 0 && _document.includes('hs_selenium'));
const paymentsTimeoutApiClient = promiseClient(
// Order matters here. The timeout needs to be set before using HubapiStack
createStack(set('timeout', isAcceptanceTest ? 34000 : 18000), HubapiStack));
export default paymentsTimeoutApiClient;