import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { FETCH_CRM_OBJECT_V2 } from '../../queries';
import { useQuery as useDfcQuery } from 'data-fetching-client';
import { GET_PROPERTY_DEFINITIONS } from '../../dfc-queries';
import memoizeOne from 'react-utils/memoizeOne';
import { useTrackCrmObjectChanges } from './useTrackCrmObjectChanges';
const makeCrmObject = memoizeOne((fetchedCrmObject, propertyDefinitions) => {
  const allPropertiesMap = (propertyDefinitions || []).reduce((acc, propertyDef) => {
    acc.set(propertyDef.property.name, {
      name: propertyDef.property.name,
      objectTypeId: propertyDef.objectTypeId || '',
      label: propertyDef.property.label || '',
      deleted: propertyDef.property.deleted || null,
      hidden: propertyDef.property.hidden || false,
      hubspotDefined: propertyDef.property.hubspotDefined || null,
      dataSensitivity: propertyDef.property.dataSensitivity || 'none',
      permission: propertyDef.permission || undefined,
      source: null,
      value: null
    });
    return acc;
  }, new Map());
  for (const propertyValue of fetchedCrmObject.allProperties || []) {
    const propertyDef = allPropertiesMap.get(propertyValue.name);
    if (propertyDef) {
      allPropertiesMap.set(propertyValue.name, Object.assign({}, propertyDef, {
        value: propertyValue.value,
        source: propertyValue.source
      }));
    }
  }
  return {
    id: fetchedCrmObject.id,
    objectTypeDefinition: {
      id: fetchedCrmObject.objectTypeDefinition.id,
      name: fetchedCrmObject.objectTypeDefinition.name,
      singularForm: fetchedCrmObject.objectTypeDefinition.singularForm,
      pluralForm: fetchedCrmObject.objectTypeDefinition.pluralForm,
      pipelineStagePropertyName: fetchedCrmObject.objectTypeDefinition.pipelineStagePropertyName
    },
    userPermissions: fetchedCrmObject.userPermissions,
    allPropertiesMap
  };
});
export const useCrmObject = (objectTypeId, objectId) => {
  const {
    data: crmObjectData,
    error: crmObjectError,
    loading: crmObjectLoading,
    refetch: _refetch,
    updateQuery
  } = useQuery(FETCH_CRM_OBJECT_V2, {
    variables: {
      objectId,
      objectTypeId
    }
  });
  useTrackCrmObjectChanges(objectTypeId, objectId, crmObjectData === null || crmObjectData === void 0 ? void 0 : crmObjectData.crmObject);
  const {
    data: propertyDefinitionsData,
    loading: propertyDefinitionsLoading,
    error: propertyDefinitionsError
  } = useDfcQuery(GET_PROPERTY_DEFINITIONS, {
    variables: {
      objectTypeId
    }
  });

  // sometimes refetch returns undefined instead of a promise, so ensure that this returns a promise
  // should be fixed in apollo 3.5 https://github.com/apollographql/apollo-client/issues/6816#issuecomment-972514035
  // at the time when this fix was added HubSpot was on apollo 3.4.15
  const refetch = useCallback(async (...args) => {
    return await _refetch(...args);
  }, [_refetch]);
  const crmObject = useMemo(() => {
    if (!(crmObjectData !== null && crmObjectData !== void 0 && crmObjectData.crmObject) || !(propertyDefinitionsData !== null && propertyDefinitionsData !== void 0 && propertyDefinitionsData.propertyDefinitions)) {
      return undefined;
    }
    return makeCrmObject(crmObjectData.crmObject, propertyDefinitionsData.propertyDefinitions);
  }, [crmObjectData === null || crmObjectData === void 0 ? void 0 : crmObjectData.crmObject, propertyDefinitionsData === null || propertyDefinitionsData === void 0 ? void 0 : propertyDefinitionsData.propertyDefinitions]);
  const updateCrmObjectCache = useCallback(updates => {
    updateQuery(prev => {
      const newResult = Object.assign({}, prev);
      if (!newResult.crmObject || !newResult.crmObject.allProperties) {
        return newResult;
      }
      const updatedProperties = newResult.crmObject.allProperties.map(property => {
        if (Object.hasOwnProperty.call(updates, property.name)) {
          var _updates$property$nam;
          return Object.assign({}, property, {
            value: (_updates$property$nam = updates[property.name]) !== null && _updates$property$nam !== void 0 ? _updates$property$nam : null
          });
        }
        return property;
      });
      return Object.assign({}, newResult, {
        crmObject: Object.assign({}, newResult.crmObject, {
          allProperties: updatedProperties
        })
      });
    });
  }, [updateQuery]);
  return {
    crmObject,
    error: crmObjectError || propertyDefinitionsError,
    loading: crmObjectLoading || propertyDefinitionsLoading,
    refetch,
    updateCrmObjectCache
  };
};