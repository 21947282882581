const cssKeywords = ['0', 'auto', 'inherit', 'initial', 'none', 'revert', 'revert-layer', 'unset'];
export const isDefined = value => value !== undefined && value !== null;
const isCSSKeyword = value => {
  return cssKeywords.includes(value);
};
const isNegativeSpacing = value => value.startsWith('-space');
export const makeFlexDeclaration = (propertyName, value) => {
  if (!isDefined(value)) {
    return;
  }
  return `${propertyName}: ${value};`;
};
export const makeCustomProperty = value => {
  if (!value) {
    return;
  }
  if (value === '0') {
    return '0rem';
  }
  if (isCSSKeyword(value)) {
    return value;
  }
  return `var(--layout-lib-${value})`;
};
export const makeSpacingCustomProperty = value => {
  if (!value) {
    return;
  }
  if (value === '0') {
    return '0rem';
  }
  if (isCSSKeyword(value)) {
    return value;
  }
  if (isNegativeSpacing(value)) {
    return `var(--layout-lib-negative${value})`;
  }
  return `var(--layout-lib-${value})`;
};
export const makeBorderCustomProperty = value => {
  if (!value) {
    return;
  }
  if (value === 'transparent') {
    return 'var(--layout-lib-border-transparent)';
  }
  if (value === 'none' || value === '0') {
    return 'var(--layout-lib-border-none)';
  }
  return `var(--layout-lib-${value})`;
};