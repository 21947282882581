import { useCallback, useEffect, useState } from 'react';
import debug from 'growth-payments-core/debug/console';
import { useAuth } from 'growth-payments-core/auth/user';
import pick from 'growth-payments-core/object/pick';

/**
 * A react useState using localStorage as a backend. This is useful for storing
 * data that you want to persist across page loads.
 *
 * To support a key that may not be loaded on the first render, you can pass
 * null initially and it will be populated with the value from localStorage once
 * it is available.
 */

function setLocalStorageItem(key, value) {
  if (!key) {
    return;
  }
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    debug.error('error setting localStorage item', key, e);
  }
}
export function fetchLocalStorageItem(key) {
  if (!key) {
    return null;
  }
  try {
    const item = localStorage.getItem(key);
    if (item !== undefined && item !== null) {
      return JSON.parse(item);
    }
  } catch (e) {
    debug.error('error fetching localStorage item', key, e);
  }
  return null;
}
function removeLocalStorageItem(key) {
  if (!key) {
    return;
  }
  try {
    localStorage.removeItem(key);
  } catch (e) {
    debug.error('error removing localStorage item', key, e);
  }
}
export const useLocalStorageState = (key, initialValue, filterKeys) => {
  const fetchFromLocalStorage = useCallback(() => {
    const value = fetchLocalStorageItem(key);
    return value === null || value === undefined ? initialValue : value;
  }, [key, initialValue]);
  const [storedValue, setStoredValue] = useState(fetchFromLocalStorage());

  // If the key changes, fetch the localStorage value from our new key.
  useEffect(() => {
    debug.log('creating a scoped local storage item with key', key);
    setStoredValue(fetchFromLocalStorage());
  }, [key, fetchFromLocalStorage]);

  // If the state changes, update the localStorage value.
  useEffect(() => {
    const valueToStore = filterKeys ? pick(storedValue, filterKeys) : storedValue;
    setLocalStorageItem(key, valueToStore);
  }, [key, storedValue, filterKeys]);
  return [storedValue, setStoredValue];
};
export const useReadonlyLocalStorageState = (key, defaultValue) => {
  const fetchFromLocalStorage = useCallback(() => {
    return fetchLocalStorageItem(key) || defaultValue;
  }, [key, defaultValue]);
  const [storedValue, setStoredValue] = useState(fetchFromLocalStorage);

  // If the key changes, fetch the localStorage value from our new key.
  useEffect(() => {
    debug.log('creating a readonly scoped local storage fetcher with key', key);
    setStoredValue(fetchFromLocalStorage);
  }, [key, fetchFromLocalStorage]);
  const refresh = useCallback(() => {
    setStoredValue(fetchFromLocalStorage());
  }, [fetchFromLocalStorage]);
  return [storedValue, refresh];
};
export const getScopedLocalStorageKey = (userId, portalId, key) => `${userId}-${portalId}-${key}`;

/**
 * The same thing as useLocalStorageState, but with a scoped key based on the user ID and portal ID.
 */
export const useScopedLocalStorageState = (key, initialValue, filterKeys) => {
  const [auth] = useAuth();
  const userId = auth && auth.user && auth.user.user_id;
  const portalId = auth && auth.user && auth.portal.portal_id;
  const formStateKey = userId && portalId ? getScopedLocalStorageKey(userId, portalId, key) : null;
  return useLocalStorageState(formStateKey, initialValue, filterKeys);
};

/**
 * The same thing as useScopedLocalStorageState, but readonly
 */
export const useReadonlyScopedLocalStorageState = (key, initialValue) => {
  const [auth] = useAuth();
  const userId = auth && auth.user && auth.user.user_id;
  const portalId = auth && auth.portal && auth.portal.portal_id;
  const formStateKey = userId && portalId ? getScopedLocalStorageKey(userId, portalId, key) : null;
  return useReadonlyLocalStorageState(formStateKey, initialValue);
};
export const useCleanupScopedLocalStorage = keys => {
  const [auth] = useAuth();
  const userId = auth && auth.user && auth.user.user_id;
  const portalId = auth && auth.user && auth.portal.portal_id;
  return () => keys.forEach(k => {
    const formStateKey = userId && portalId ? getScopedLocalStorageKey(userId, portalId, k) : null;
    removeLocalStorageItem(formStateKey);
  });
};