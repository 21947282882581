import { useCallback, useState } from 'react';
import { useMessageRelay } from './useMessageRelay';
import { useSendCrmMessage } from './useSendCrmMessage';
import { RELAY_IFRAME_MESSAGE_TYPE, CRM_MESSAGE_BUS_RELAY } from './constants';
function isMessageBusRelayMessage(unparsedPayload) {
  if (unparsedPayload.type === RELAY_IFRAME_MESSAGE_TYPE && Object.prototype.hasOwnProperty.call(unparsedPayload, 'topic') && Object.prototype.hasOwnProperty.call(unparsedPayload, 'payload')) {
    return true;
  }
  return false;
}
export const useUIIFrameConnector = () => {
  const [sendIframeMessage, setSendIframeMessage] = useState(() => () => {});
  const sendCrmMessage = useSendCrmMessage();
  useMessageRelay((topic, data) => {
    sendIframeMessage(CRM_MESSAGE_BUS_RELAY, {
      topic,
      data
    });
  });

  // When the iFrame is connected, store  the function
  // that sendsMessages into the UIIFrame
  const onUIIFrameReady = useCallback(onReadyArgs => {
    // It's necessary to call set*State* with a wrapper function
    // because set*State* uses functions to caluculate the new state.
    setSendIframeMessage(() => onReadyArgs.sendMessage);
  }, []);
  const onUIIFrameMessage = useCallback(iframeMessage => {
    if (iframeMessage && iframeMessage.payload) {
      const iFramePayload = iframeMessage.payload;
      if (isMessageBusRelayMessage(iFramePayload)) {
        sendCrmMessage(iFramePayload.topic, iFramePayload.payload.data, {
          sourceId: iFramePayload.payload.envelope.sourceId,
          targetId: iFramePayload.payload.envelope.targetId
        });
      }
    }
  }, [sendCrmMessage]);
  return {
    onUIIFrameReady,
    onUIIFrameMessage
  };
};