import { track } from './track';
import { useEffect } from 'react';
import debug from 'growth-payments-core/debug/console';
/**
 * Tracks renders of a component.
 *
 * **event** - The event to track
 *
 * **memo** - An array of values to memoize the tracking on. If any of the values change, the tracking will be triggered again. defaults to []
 *
 * **...properties** - The remaining props are the properties on that event.
 *
 * @example
 * ```tsx
 * <TrackRender event={events.growthPaymentsPageView} properties={{screen="stripe-kyc-describeYourBusiness"}} memo={[stepIndex]} />
 * ```
 */
const TrackRender = ({
  event,
  memo = [],
  properties
}) => {
  useEffect(() => {
    debug.log('TrackRender', event, properties);
    track(event, properties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, memo);
  return null;
};
export default TrackRender;