import { useUser, getUser } from './user';
import { useSet } from 'growth-payments-core/set/useSet';
import get from 'growth-payments-core/object/get';
import { useCallback } from 'react';

/**
 * Simple enum to keep track of the scopes we care about
 */
export let Scopes;
(function (Scopes) {
  Scopes["paymentsEligible"] = "payments-eligible";
  Scopes["paymentLinksRead"] = "payment-links-read";
  Scopes["paymentLinksWrite"] = "payment-links-write";
  Scopes["paymentLinksDelete"] = "payment-links-delete";
  Scopes["superAdmin"] = "super-admin";
  Scopes["commerceSpecialistsGrowthContactAccess"] = "commerce-specialists-growth-contact-access";
})(Scopes || (Scopes = {}));
export const useScopes = () => {
  const user = useUser();
  const scopesSet = useSet(get(user, 'scopes'));
  return scopesSet;
};
export const getScopes = () => {
  const user = getUser();
  const scopesSet = get(user, 'scopes');
  return scopesSet;
};
export function hasScope(scope, scopes) {
  if (Array.isArray(scopes)) {
    return scopes.includes(scope);
  }
  return scopes.has(scope);
}
export const useHasAllScopes = () => {
  const scopesSet = useScopes();
  return useCallback((...scopes) => scopes.every(scope => scopesSet.has(scope)), [scopesSet]);
};