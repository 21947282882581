// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
import { defineKeyStore } from 'crm_data/store/KeyStore';
import { ADD_SAMPLE_TIMELINE_ITEMS, FETCH_TIMELINE_ITEMS_SUCCESS, POLLING_FETCH_TIMELINE_ITEMS_SUCCESS, DELETE_TIMELINE_ENGAGEMENT, ADD_ENGAGEMENT, CHECK_SUBJECT_AND_ADD_ENGAGEMENT, FETCH_TIMELINE_ITEMS_FOR_VFP_SUCCESS, FETCHING_INITIAL_TIMELINE_EVENTS_STARTED, EMAIL_TRACKING_EVENT_DELETED, ENGAGEMENTS_UPDATED
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'crm_... Remove this comment to see the full error message
} from 'crm_data/actions/ActionTypes';
import { Map as ImmutableMap, List } from 'immutable';
import { TRACKED_EVENT_TYPES, EMAIL_EVENT_EVENT_TYPES, EVENT_SIDEKICK } from '../../timeline/constants/TimelineConstants';
import { normalizeTypeId } from 'customer-data-objects/constants/ObjectTypeIds';
import { isOnSubjectRecord } from '../helpers/isOnSubjectRecord';
const {
  EMAIL_CLICK,
  EMAIL_OPEN
} = TRACKED_EVENT_TYPES;
function getId(timelineItem) {
  const id = timelineItem.getIn(['eventData', 'sentBy', 'id']) || timelineItem.getIn(['engagement', 'id']) || timelineItem.getIn(['eventData', 'engagement', 'id']) || timelineItem.getIn(['eventData', 'id']) || timelineItem.get('id');
  return `${id}`;
}
function getTimelineItems(data) {
  if (data.sampleEvents) {
    return data.sampleEvents;
  }
  return data.get('events');
}
function getSidekickEvents(timelineItem) {
  const type = timelineItem.getIn(['eventData', 'type']);
  const sources = timelineItem.getIn(['eventData', 'sources']);
  if (type === EMAIL_OPEN || type === EMAIL_CLICK) {
    return sources.reduce((map, source) => {
      const timestamp = source.get('timestamp');
      const id = `${type}-${timestamp}`;
      const sourceEvent = timelineItem.set('timestamp', timestamp).setIn(['eventData', 'id'], id).setIn(['eventData', 'sources'], List([source]));
      return map.set(id, sourceEvent);
    }, ImmutableMap());
  }
  return null;
}
function getUpdatedMarketingEmail(marketingEmail1, marketingEmail2) {
  let toMarketingEmail;
  let fromMarketingEmail;
  if (marketingEmail1.hasIn(['eventData', 'obsoletedBy'])) {
    toMarketingEmail = marketingEmail2;
    fromMarketingEmail = marketingEmail1;
  } else {
    toMarketingEmail = marketingEmail1;
    fromMarketingEmail = marketingEmail2;
  }
  const subject = fromMarketingEmail.getIn(['eventData', 'subject']);
  const created = fromMarketingEmail.getIn(['eventData', 'created']);
  const type = toMarketingEmail.getIn(['eventData', 'type']);
  toMarketingEmail = toMarketingEmail.setIn(['eventData', 'subject'], subject);

  // Set created because this is what we use to fetch correlatedEvents,
  // which has the reason why the email was dropped
  if (type === EMAIL_EVENT_EVENT_TYPES.DROPPED) {
    toMarketingEmail = toMarketingEmail.setIn(['eventData', 'created'], created);
  }
  return toMarketingEmail;
}
export default defineKeyStore({
  namespace: 'TIMELINE_EVENTS',
  idIsValid(id) {
    return typeof id === 'string';
  }
}).defineResponseTo([ADD_SAMPLE_TIMELINE_ITEMS, FETCH_TIMELINE_ITEMS_SUCCESS, POLLING_FETCH_TIMELINE_ITEMS_SUCCESS, FETCH_TIMELINE_ITEMS_FOR_VFP_SUCCESS], (state, data) => {
  const timelineItems = getTimelineItems(data);
  if (!timelineItems || !timelineItems.size) {
    return state;
  }
  timelineItems.forEach(timelineItem => {
    if (timelineItem.get('etype') === EVENT_SIDEKICK) {
      state = state.merge(getSidekickEvents(timelineItem));
    } else {
      const id = getId(timelineItem);
      if (state.get(id) && timelineItem.getIn(['eventData', 'cosEmail'])) {
        timelineItem = getUpdatedMarketingEmail(timelineItem, state.get(id));
      }
      state = state.set(id, timelineItem);
    }
  });
  if (!data.sampleEvents && data !== null && data !== void 0 && data.get('originalPayload')) {
    var _data$get, _data$get2;
    state = state.set('subjectId', (_data$get = data.get('originalPayload')) === null || _data$get === void 0 ? void 0 : _data$get.get('subjectId'));
    state = state.set('objectType', normalizeTypeId((_data$get2 = data.get('originalPayload')) === null || _data$get2 === void 0 ? void 0 : _data$get2.get('objectType')));
  }
  return state;
}).defineResponseTo(DELETE_TIMELINE_ENGAGEMENT, (state, {
  engagement
}) => {
  const id = getId(engagement);
  return state.delete(id);
}).defineResponseTo(ADD_ENGAGEMENT, (state, {
  engagement
}) => {
  const id = getId(engagement);
  return state.set(id, engagement);
}).defineResponseTo(CHECK_SUBJECT_AND_ADD_ENGAGEMENT, (state, {
  engagement,
  subjectId,
  objectType
}) => {
  const normalizedObjectType = normalizeTypeId(objectType);
  if (subjectId !== state.get('subjectId') || normalizedObjectType !== state.get('objectType') || !isOnSubjectRecord(normalizedObjectType, subjectId)) {
    return state;
  }
  const id = getId(engagement);
  return state.set(id, engagement);
}).defineResponseTo(ENGAGEMENTS_UPDATED, (state, engagements) => {
  engagements.forEach((engagement, key) => {
    if (!engagement) {
      state = state.delete(`${key}`);
      return;
    }
    const id = getId(engagement);
    state = state.setIn([id, 'eventData'], engagement);
  });
  return state;
}).defineResponseTo(EMAIL_TRACKING_EVENT_DELETED, (state, {
  timelineItem,
  trackerKey
}) => {
  if (!timelineItem && !trackerKey) {
    return state;
  }
  if (timelineItem) {
    // delete individual tracking activity
    const id = timelineItem.getIn(['eventData', 'id']);
    return state.delete(id);
  }

  // delete all tracking activities with the given trackerKey
  return state.filterNot((evt, key) => key !== 'subjectId' && key !== 'objectType' && evt.getIn(['eventData', 'tracker_key'], '') === trackerKey);
}).defineResponseTo(FETCHING_INITIAL_TIMELINE_EVENTS_STARTED, () => {
  return ImmutableMap();
}).register();