import { gates } from '../constants/gates';
const keys = {
  CONVERSATIONAL_ENRICHMENT_BETA: 'conversationalEnrichmentBeta',
  CONVERSATIONAL_ENRICHMENT_EMPLOYMENT_CHANGE: 'conversationalEnrichmentEmploymentChange',
  CONVERSATIONAL_ENRICHMENT_OUT_OF_OFFICE: 'conversationalEnrichmentOutOfOffice',
  ENRICHMENT_ASSOCIATIONS: 'enrichmentAssociations',
  ENRICHMENT_ASSOCIATIONS_LISTS: 'enrichmentAssociationsLists',
  ENRICHMENT_AUTO_LAST_ENGAGEMENT_COMPANIES: 'enrichmentAutoLastEngagementCompanies',
  ENRICHMENT_AUTO_LAST_ENGAGEMENT_CONTACTS: 'enrichmentAutoLastEngagementContacts',
  ENRICHMENT_AUTO_SETTING_NUDGE: 'enrichmentAutoSettingNudge',
  ENRICHMENT_SMART_PROPERTIES: 'enrichmentSmartProperties'
};
const gateLookup = {
  [keys.CONVERSATIONAL_ENRICHMENT_BETA]: gates.CONVERSATIONAL_ENRICHMENT_BETA,
  [keys.CONVERSATIONAL_ENRICHMENT_EMPLOYMENT_CHANGE]: gates.CONVERSATIONAL_ENRICHMENT_EMPLOYMENT_CHANGE,
  [keys.CONVERSATIONAL_ENRICHMENT_OUT_OF_OFFICE]: gates.CONVERSATIONAL_ENRICHMENT_OUT_OF_OFFICE,
  [keys.ENRICHMENT_ASSOCIATIONS]: gates.ENRICHMENT_ASSOCIATIONS,
  [keys.ENRICHMENT_ASSOCIATIONS_LISTS]: gates.ENRICHMENT_ASSOCIATIONS_LISTS,
  [keys.ENRICHMENT_AUTO_LAST_ENGAGEMENT_COMPANIES]: gates.ENRICHMENT_AUTO_LAST_ENGAGEMENT_COMPANIES,
  [keys.ENRICHMENT_AUTO_LAST_ENGAGEMENT_CONTACTS]: gates.ENRICHMENT_AUTO_LAST_ENGAGEMENT_CONTACTS,
  [keys.ENRICHMENT_AUTO_SETTING_NUDGE]: gates.ENRICHMENT_AUTO_SETTING_NUDGE,
  [keys.ENRICHMENT_SMART_PROPERTIES]: gates.ENRICHMENT_SMART_PROPERTIES
};
export function getGates(options) {
  return Object.keys(options).map(option => options[option] ? gateLookup[option] : null).filter(gate => gate !== null);
}
export function getGate(key) {
  return gateLookup[key];
}