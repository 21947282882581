import { CardAction, CardEmptyState, ChickletHoverActions, ChickletPrimaryDisplayLabel, ChickletSecondaryDisplayLabels } from '../constants/BehaviorTypes';
import { getDefaultChickletHoverActions } from '../behaviors/default/getDefaultChickletHoverActions';
import CampaignCardActionWrapperAsync from '../behaviors/campaign/CampaignCardActionWrapperAsync';
import DefaultCardEmptyState from '../behaviors/default/DefaultCardEmptyState';
import DefaultChickletPrimaryDisplayLabel from '../behaviors/default/DefaultChickletPrimaryDisplayLabel';
import getDefaultChickletSecondaryDisplayLabels from '../behaviors/default/getDefaultChickletSecondaryDisplayLabels';
export const Campaign = {
  [CardEmptyState]: () => DefaultCardEmptyState,
  [CardAction]: () => CampaignCardActionWrapperAsync,
  [ChickletHoverActions]: () => getDefaultChickletHoverActions,
  [ChickletPrimaryDisplayLabel]: () => DefaultChickletPrimaryDisplayLabel,
  [ChickletSecondaryDisplayLabels]: () => getDefaultChickletSecondaryDisplayLabels
};