import { registerQuery } from 'data-fetching-client';
import { fetchPaymentsAccount, fetchPaymentsAccountEligibility } from '../api/PaymentsAccountApi';
import { fetchPaymentsAccountPersons } from '../api/PaymentsPersonsApi';
export const GET_PAYMENT_ACCOUNT = registerQuery({
  fieldName: 'paymentAccount',
  fetcher: fetchPaymentsAccount
});
export const GET_PAYMENT_ACCOUNT_PERSONS = registerQuery({
  fieldName: 'paymentAccountPersons',
  fetcher: fetchPaymentsAccountPersons
});
export const GET_PAYMENTS_ELIGIBILITY = registerQuery({
  fieldName: 'paymentsEnrollmentEmbedEligibility',
  fetcher: fetchPaymentsAccountEligibility
});

// IMPORTANT: GET_COMMERCE_HOME_ACCOUNT_ELIGIBILITY fieldName must match the
// fieldName in commerce-home-ui exactly to ensure cache updates propagate
// to the parent app.
// (https://git.hubteam.com/HubSpotProtected/commerce-home-ui/blob/master/commerce-home-ui/static/js/data/paymentsAccountQueries.ts#L5)
export const GET_COMMERCE_HOME_ACCOUNT_ELIGIBILITY = registerQuery({
  fieldName: 'paymentsAccountEligibility',
  fetcher: fetchPaymentsAccountEligibility
});