import { useMemo } from 'react';
import { gate } from 'hub-http/gates';
import { useQuery } from 'data-fetching-client';
import isPortalSpecificObjectType from 'customer-data-objects/crmObject/isPortalSpecificObjectType';
import { isAppObjectTypeId } from 'customer-data-objects/crmObject/isAppObjectTypeId';
import { CRM_PAGE_EDITOR_APP_SETTINGS } from '../data/FrameworkBuilderService';
import { CUSTOMIZABLE_LOCATIONS } from '../data/static/CustomizableLocations';

/**
 * Custom hook to get the list of enabled locations for a specific object type from the crmPageEditor Framework App Settings.
 * For HubSpot-defined object types, this checks FAS to determine which locations are enabled.
 * For portal-specific and App object types, all customizable locations are enabled by default.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {string} params.objectTypeId - The object type ID to check.
 * @param {string[]} params.scopes - The scopes to check.
 * @param {string[]} params.gates - The gates to check.
 * @returns {Object} - An object containing:
 *   - `locations` (CustomizableLocation[]): Array of locations.
 *   - `loading` (boolean): Whether the app settings data is currently loading.
 *   - `error` (Error | undefined): Any error that occurred while fetching the app settings data.
 *
 * @example
 * ```ts
 * const { locations, loading, error } = useEnabledLocationsForObjectType({
 *   objectTypeId: '0-5',
 *   scopes: [...],
 *   gates: [...],
 * });
 *
 * console.log(locations);
 * // [
 * //   'CRM_OBJECT_PREVIEW',
 * //   'HELPDESK_SIDEBAR',
 * //   'CRM_RECORD_PAGE',
 * // ]
 * ```
 */
export const useEnabledLocationsForObjectType = ({
  objectTypeId,
  scopes,
  gates
}) => {
  const isHubSpotDefinedObjectType = !(isPortalSpecificObjectType(objectTypeId) || isAppObjectTypeId(objectTypeId));
  const {
    data: appSettingsData,
    loading: appSettingsLoading,
    error: appSettingsError
  } = useQuery(CRM_PAGE_EDITOR_APP_SETTINGS, {
    variables: {
      objectTypeId
    },
    skip: !isHubSpotDefinedObjectType
  });
  const allEnabledLocationsMetadata = useMemo(() => {
    var _appSettingsData$crmP;
    if (!isHubSpotDefinedObjectType) {
      return CUSTOMIZABLE_LOCATIONS.map(location => ({
        location
      }));
    }
    if (appSettingsLoading || appSettingsError || !(appSettingsData !== null && appSettingsData !== void 0 && (_appSettingsData$crmP = appSettingsData.crmPageEditorAppSettings) !== null && _appSettingsData$crmP !== void 0 && (_appSettingsData$crmP = _appSettingsData$crmP.metadata.enabled) !== null && _appSettingsData$crmP !== void 0 && _appSettingsData$crmP.value)) {
      return [];
    }
    return appSettingsData.crmPageEditorAppSettings.metadata.enabledLocations.value;
  }, [isHubSpotDefinedObjectType, appSettingsLoading, appSettingsError, appSettingsData]);
  const enabledLocationsFilteredByGatesAndScopes = useMemo(() => allEnabledLocationsMetadata.filter(({
    gates: locationGates,
    scopes: locationScopes
  }) => {
    const isUngatedForLocation = !locationGates || locationGates.every(locationGate => gates.includes(locationGate) ||
    // eslint-disable-next-line hubspot-dev/no-bare-gate-strings
    gates.includes(gate(locationGate)));
    const isScopedForLocation = !locationScopes || locationScopes.every(scope => scopes.includes(scope));
    return isUngatedForLocation && isScopedForLocation;
  }).map(({
    location
  }) => location), [allEnabledLocationsMetadata, gates, scopes]);
  return {
    locations: enabledLocationsFilteredByGatesAndScopes,
    loading: appSettingsLoading,
    error: appSettingsError
  };
};