import { CrmLogger } from 'customer-data-tracking/loggers';
import Raven from 'raven-js';
import { useMemo, useCallback, useState, useEffect } from 'react';
import useFetchAssociationTypesData from '../context/useFetchAssociationTypesData';
import { useFetchAllAssociationTypesBetweenObjectIds } from './useFetchAllAssociationTypesBetweenObjectIds';
import { POLL_INTERVAL, UNKNOWN_ACTION, getImpliedUnlabeledAssociationDetails, useFetchAssociatedObjectsCardAssociations } from './useFetchAssociatedObjectsCardAssociations';
import { useFetchAssociatedObjectsCardObject } from './useFetchAssociatedObjectsCardObject';
import { useFetchAssociationAuditSources } from './useFetchAssociationAuditSources';
import { useFetchPropertyDefinitions } from './useFetchPropertyDefinitions';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { useOnCrmMessageTopic } from 'crm-message-bus/useOnCrmMessageTopic';
import { cancelDelayUntilIdle, delayUntilIdle } from '../helpers/delayUntilIdle';
export const useFetchAssociatedObjectsCardData = (props, cardContext) => {
  const {
    isCollapsed
  } = props.cardData;
  const [shouldDeferDataRequests, setShouldDeferDataRequests] = useState(Boolean(isCollapsed));
  useEffect(() => {
    if (!isCollapsed) {
      setShouldDeferDataRequests(false);
      return;
    }
    const idleCallbackId = delayUntilIdle(() => {
      setShouldDeferDataRequests(false);
    });
    return () => {
      if (idleCallbackId !== undefined) {
        cancelDelayUntilIdle(idleCallbackId);
      }
    };
  }, [isCollapsed]);
  const {
    objectId,
    objectTypeId
  } = cardContext;
  const {
    toObjectTypeId
  } = props.cardData.configuration;
  const associationTypesData = useFetchAssociationTypesData({
    fromObjectTypeId: objectTypeId,
    toObjectTypeId,
    skip: shouldDeferDataRequests
  });
  const {
    associationTypes,
    associationTypesLoading,
    primaryAssociationType,
    unlabeledAssociationType
  } = associationTypesData;
  const {
    impliedUnlabeledAssociationCategory,
    impliedUnlabeledAssociationTypeId,
    impliedUnlabeledInverseAssociationTypeId
  } = getImpliedUnlabeledAssociationDetails({
    associationTypes,
    unlabeledAssociationType
  });
  const {
    associationsDataError,
    fetchMore,
    impliedUnlabeledAssociationObjects,
    loading,
    onStopPolling: stopPollingAssociationsData,
    primaryAssociationObject,
    refetch: refetchAssociationsData,
    startPolling: startPollingAssociationsData,
    updateAssociationAction
  } = useFetchAssociatedObjectsCardAssociations({
    impliedUnlabeledAssociationCategory,
    impliedUnlabeledInverseAssociationTypeId,
    fromObjectId: objectId,
    fromObjectTypeId: objectTypeId,
    toObjectTypeId,
    primaryAssociationCategory: primaryAssociationType && primaryAssociationType.associationCategory,
    primaryInverseAssociationTypeId: primaryAssociationType ? primaryAssociationType.inverseAssociationTypeId : null,
    propertyNames: props.cardData.configuration.propertyNames,
    skip: shouldDeferDataRequests,
    sortConfiguration: props.cardData.configuration.sortConfiguration
  });
  const toObjectIds = useMemo(() => {
    if (!impliedUnlabeledAssociationObjects) {
      return [];
    }
    const ids = impliedUnlabeledAssociationObjects.results.map(association => association.id);
    if (primaryAssociationObject && primaryAssociationObject.results[0] && !ids.includes(primaryAssociationObject.results[0].id)) {
      ids.push(primaryAssociationObject.results[0].id);
    }
    return ids;
  }, [impliedUnlabeledAssociationObjects, primaryAssociationObject]);
  const {
    data: allAssociationTypesBetweenObjectIdsData,
    refetch: refetchAllAssociationTypesBetweenObjectIdsData
  } = useFetchAllAssociationTypesBetweenObjectIds({
    objectId: objectId,
    objectTypeId: objectTypeId,
    toObjectIds,
    toObjectTypeId,
    skip: shouldDeferDataRequests
  });
  const {
    objectTypePermissions,
    primaryDisplayLabel,
    userPermissions
  } = useFetchAssociatedObjectsCardObject({
    objectId: Number(objectId),
    objectType: objectTypeId,
    toObjectTypeId
  });
  const {
    data: propertyDefinitions
  } = useFetchPropertyDefinitions({
    configPropertyNames: props.cardData.configuration.propertyNames,
    toObjectTypeId,
    skip: shouldDeferDataRequests
  });
  const associationSpecs = impliedUnlabeledAssociationObjects && impliedUnlabeledAssociationObjects.results.map(association => ({
    objectId: association.id,
    associationSpec: {
      associationCategory: impliedUnlabeledAssociationCategory,
      associationTypeId: impliedUnlabeledAssociationTypeId
    }
  }));
  const {
    data: associationAuditSourcesData
  } = useFetchAssociationAuditSources({
    associationSpecs,
    objectId: objectId,
    objectTypeId: objectTypeId,
    toObjectTypeId,
    skip: shouldDeferDataRequests
  });
  const handleViewMore = useCallback(() => {
    const offset = impliedUnlabeledAssociationObjects ? impliedUnlabeledAssociationObjects.offset : 0;
    CrmLogger.log('sidebar-interaction', {
      type: toObjectTypeId,
      action: 'View More Associated Objects',
      subAction: `${offset}`
    });
    fetchMore({
      variables: {
        offset
      },
      updateQuery(previousResult, {
        fetchMoreResult
      }) {
        if (!fetchMoreResult) {
          return previousResult;
        }
        return Object.assign({}, previousResult, {
          impliedUnlabeledAssociationObjects: Object.assign({}, previousResult.impliedUnlabeledAssociationObjects, fetchMoreResult.impliedUnlabeledAssociationObjects, {
            results: [...previousResult.impliedUnlabeledAssociationObjects.results, ...fetchMoreResult.impliedUnlabeledAssociationObjects.results]
          })
        });
      }
    }).catch(error => {
      Raven.captureException(error);
    });
  }, [fetchMore, impliedUnlabeledAssociationObjects, toObjectTypeId]);
  useOnCrmMessageTopic(TOPIC_NAMES.UPDATE_ASSOCIATIONS, ({
    toObjectTypeId: messageToObjectTypeId,
    objectTypeId: messageObjectTypeId,
    objectId: messageObjectId,
    actionType
  }) => {
    const associatedObjectsIds = impliedUnlabeledAssociationObjects && impliedUnlabeledAssociationObjects.results.map(result => result.id) || [];
    const legacyObjectTypeIdLogic = toObjectTypeId === messageObjectTypeId;
    const correctObjectTypeIdLogic = messageObjectTypeId === objectTypeId && (!messageToObjectTypeId || toObjectTypeId === messageToObjectTypeId);
    if ((legacyObjectTypeIdLogic || correctObjectTypeIdLogic) && [objectId, ...associatedObjectsIds].includes(+messageObjectId) && !updateAssociationAction.current) {
      // Association labels are updated "instantly" so we can refetch them directly
      refetchAllAssociationTypesBetweenObjectIdsData().catch(error => {
        Raven.captureException(error);
        throw new Error('No data returned from refetchAllAssociationTypesBetweenObjectIdsData query');
      });
      if (actionType === 'UPDATE_LABELS') {
        return;
      }
      updateAssociationAction.current = actionType || UNKNOWN_ACTION;
      startPollingAssociationsData(POLL_INTERVAL);
      // Stop polling if after 6s to prevent infinite polling
      setTimeout(() => {
        if (updateAssociationAction.current) {
          stopPollingAssociationsData();
        }
      }, 6000);
    }
  });
  useOnCrmMessageTopic(TOPIC_NAMES.UPDATE_OBJECTS, objectData => {
    const {
      objectTypeId: messageObjectTypeId
    } = objectData[0];
    if (messageObjectTypeId === toObjectTypeId) {
      startPollingAssociationsData(POLL_INTERVAL);
      // Stop polling if after 3s to prevent infinite polling
      setTimeout(() => {
        stopPollingAssociationsData();
      }, 3000);
    }
  });
  return useMemo(() => ({
    associationAuditSourcesData: associationAuditSourcesData && associationAuditSourcesData.AssociationAuditSources,
    associationsData: {
      impliedUnlabeledAssociationObjects,
      primaryAssociationObject,
      associationsDataError
    },
    associationTypesData,
    allAssociationTypesBetweenObjectIdsData,
    objectId: Number(objectId),
    objectTypeId,
    objectTypePermissions,
    primaryDisplayLabel,
    propertyDefinitions,
    refetchAssociationsData,
    refetchAllAssociationTypesBetweenObjectIdsData,
    toObjectTypeId,
    userPermissions,
    onViewMore: handleViewMore,
    isLoading: shouldDeferDataRequests || loading || associationTypesLoading
  }), [allAssociationTypesBetweenObjectIdsData, associationAuditSourcesData, associationTypesData, associationTypesLoading, associationsDataError, handleViewMore, impliedUnlabeledAssociationObjects, loading, objectId, objectTypeId, objectTypePermissions, primaryAssociationObject, primaryDisplayLabel, propertyDefinitions, refetchAllAssociationTypesBetweenObjectIdsData, refetchAssociationsData, shouldDeferDataRequests, toObjectTypeId, userPermissions]);
};