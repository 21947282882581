import { ModalDisplay } from './paymentsInterstitialModal';
export let Screen;
(function (Screen) {
  Screen["MEETINGS"] = "Meetings";
  Screen["FORMS"] = "Forms";
  Screen["CRM"] = "CRM";
  Screen["WORKFLOWS"] = "WORKFLOWS";
  Screen["INVOICE_SETTINGS"] = "Invoice_Settings";
  Screen["EMAIL"] = "Email";
  Screen["CMS"] = "CMS";
  Screen["UNKNOWN"] = "Unknown";
})(Screen || (Screen = {}));
export let Subscreen;
(function (Subscreen) {
  Subscreen["TEMPLATES"] = "Templates";
  Subscreen["OPTIONS"] = "Options";
  Subscreen["CRM_PAYMENTS"] = "Crm_Payments";
  Subscreen["CRM_PAYMENT_LINKS"] = "Crm_Payment_Links";
  Subscreen["CRM_SUBSCRIPTIONS"] = "Crm_Subscriptions";
  Subscreen["CRM_INVOICES"] = "Crm_Invoices";
  Subscreen["INVOICE_CUSTOMIZATION"] = "Invoice_Customization";
  Subscreen["INVOICE_AUTOMATION"] = "Invoice_Automation";
  Subscreen["CMS_PAGES"] = "Pages";
  Subscreen["CMS_BLOG"] = "Blog";
})(Subscreen || (Subscreen = {}));
const CMS_APPS = {
  BLOG: 'BLOG',
  PAGES: 'PAGES'
};
export const CrmSubscreens = new Set([Subscreen.CRM_PAYMENTS, Subscreen.CRM_PAYMENT_LINKS, Subscreen.CRM_SUBSCRIPTIONS, Subscreen.CRM_INVOICES]);
export const isCrmSubscreen = subscreen => !!subscreen && CrmSubscreens.has(subscreen);
export const DISABLED_MODALS = {
  [Screen.CRM]: [ModalDisplay.PAYMENT_LINK],
  [Screen.FORMS]: [],
  [Screen.MEETINGS]: []
};
export const getCmsSubscreenFromAppName = appName => {
  switch (appName) {
    case CMS_APPS.BLOG:
      return Subscreen.CMS_BLOG;
    case CMS_APPS.PAGES:
      return Subscreen.CMS_PAGES;
    default:
    // noop
  }
};