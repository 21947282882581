import useCommerceEnrollmentStatus from './useCommerceEnrollmentStatus';
import { ToolStatus } from './useCommerceToolStatus';
export const PaymentLinkStatus = {
  LOADING: 'LOADING',
  PRE_ENROLL_PRE_TOOL: 'PRE_ENROLL_PRE_TOOL',
  PRE_ENROLL_POST_TOOL: 'PRE_ENROLL_POST_TOOL',
  POST_ENROLL_PRE_TOOL: 'POST_ENROLL_PRE_TOOL',
  POST_ENROLL_POST_TOOL: 'POST_ENROLL_POST_TOOL'
};
const usePaymentLinkStatus = () => {
  const {
    toolUsage,
    isEnrolledInPayments,
    isLoading
  } = useCommerceEnrollmentStatus();
  if (isLoading || !toolUsage) {
    return {
      paymentLinkStatus: PaymentLinkStatus.LOADING,
      isLoading: true
    };
  }
  const hasPaymentLink = toolUsage.paymentLinks === ToolStatus.POST_TOOL;
  let paymentLinkStatus = PaymentLinkStatus.LOADING;
  if (isEnrolledInPayments) {
    paymentLinkStatus = hasPaymentLink ? PaymentLinkStatus.POST_ENROLL_POST_TOOL : PaymentLinkStatus.POST_ENROLL_PRE_TOOL;
  } else {
    paymentLinkStatus = hasPaymentLink ? PaymentLinkStatus.PRE_ENROLL_POST_TOOL : PaymentLinkStatus.PRE_ENROLL_PRE_TOOL;
  }
  return {
    paymentLinkStatus,
    isLoading
  };
};
export default usePaymentLinkStatus;