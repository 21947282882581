import { gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import Raven from 'raven-js';
import { registerMutation, useDataFetchingClient } from 'data-fetching-client';
import { useMutation } from 'data-fetching-client';
import { GET_FAS_LOCATION } from '../useCrmLocationResolverQuery';
export const collapseSdkLocationCard = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"mutation\",\"name\":{\"kind\":\"Name\",\"value\":\"collapseSdkLocationCard\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"CollapseCardInput\"}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"collapseCard\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"input\"}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"status\"}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "collapseSdkLocationCard"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "input"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "CollapseCardInput"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "collapseCard"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "input"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "input"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "status"
            }
          }]
        }
      }]
    }
  }]
});
export const useCrmLocationService = ({
  additionalLocationData,
  location,
  httpClient,
  onUpdateCardOrder
}) => {
  const apolloClient = useApolloClient();
  const dataFetchingClient = useDataFetchingClient();
  const registeredUpdateCardOrderMutation = useMemo(() => {
    if (!additionalLocationData.objectTypeId) {
      return registerMutation({
        fieldName: 'cardOrder',
        args: ['orderedCardIds'],
        fetcher() {
          Raven.captureException(new Error('useCrmLocationService.updateCardOrder: attempt to update card order with either objectTypeId or httpClient absent, card order will not be saved'), {
            extra: {
              objectTypeId: additionalLocationData.objectTypeId,
              httpClientProvided: !!httpClient
            }
          });
          return new Promise(resolve => resolve(null));
        }
      });
    }
    return registerMutation({
      fieldName: 'cardOrder',
      args: ['orderedCardIds'],
      fetcher({
        orderedCardIds
      }) {
        return httpClient.put(`crm-record-cards/v2/card-configuration/${additionalLocationData.objectTypeId}/order`, {
          data: {
            location,
            order: orderedCardIds
          }
        });
      }
    });
  }, [additionalLocationData.objectTypeId, httpClient, location]);
  const [updateCardOrderMutation] = useMutation(registeredUpdateCardOrderMutation);
  const updateCachedCardOrder = useMemo(() => {
    return orderedCardIds => {
      if (additionalLocationData.objectTypeId !== undefined) {
        const objectTypeId = additionalLocationData.objectTypeId;
        // Update cached location regardless of whether the mutation succeeds to ensure the UI is always in sync
        // The mutation only updates the server state, not the client state affecting subsequent page loads
        const cachedLocation = dataFetchingClient.readQuery({
          query: GET_FAS_LOCATION,
          variables: {
            objectTypeId,
            objectId: additionalLocationData.objectId,
            location
          }
        });
        if (cachedLocation !== null) {
          // TODO: this does not account for nested containers, which also do not work with
          // with the rest of the reordering logic, this is something that will need
          // investigation before we can support nested containers
          const reorderedCards = orderedCardIds.map(cardId => cachedLocation.location.container.contents.find(content => content.type === 'CRM_CARD_DISPLAY' && content.cardId === cardId)).filter((content
          // need type predicate here or TS will still think there are undefined values
          ) => {
            if (!content) {
              Raven.captureException(new Error('useCrmLocationService.updateCachedCardOrder: cardId not found in cached location'));
            }
            return !!content;
          });
          // catches anything not included in the card order update, like containers
          const nonReferencedContent = cachedLocation.location.container.contents.filter(content => content.type !== 'CRM_CARD_DISPLAY' || !orderedCardIds.includes(content.cardId));
          const updatedLocation = Object.assign({}, cachedLocation.location, {
            container: Object.assign({}, cachedLocation.location.container, {
              contents: [...reorderedCards, ...nonReferencedContent]
            })
          });
          dataFetchingClient.writeQuery({
            query: GET_FAS_LOCATION,
            variables: {
              objectTypeId,
              objectId: additionalLocationData.objectId,
              location
            },
            data: {
              location: updatedLocation
            }
          });
        }
      }
    };
  }, [additionalLocationData.objectId, additionalLocationData.objectTypeId, dataFetchingClient, location]);
  const updateIsCardCollapsed = useMemo(() => {
    if (additionalLocationData.objectTypeId !== undefined) {
      const objectTypeId = additionalLocationData.objectTypeId;
      return (cardId, collapsed) => {
        // Update cached location regardless of whether the mutation succeeds to ensure the UI is always in sync
        // The mutation only updates the server state, not the client state affecting subsequent page loads
        const cachedLocation = dataFetchingClient.readQuery({
          query: GET_FAS_LOCATION,
          variables: {
            objectTypeId,
            objectId: additionalLocationData.objectId,
            location
          }
        });
        if (cachedLocation !== null) {
          const updatedLocation = Object.assign({}, cachedLocation.location, {
            container: Object.assign({}, cachedLocation.location.container, {
              contents: cachedLocation.location.container.contents.map(content => {
                // Validate it is a card
                if (content.type === 'CRM_CARD_DISPLAY' && 'collapsed' in content && content.cardId === cardId) {
                  return Object.assign({}, content, {
                    collapsed
                  });
                }
                return content;
              })
            })
          });
          dataFetchingClient.writeQuery({
            query: GET_FAS_LOCATION,
            variables: {
              objectTypeId,
              objectId: additionalLocationData.objectId,
              location
            },
            data: {
              location: updatedLocation
            }
          });
        }
        apolloClient.mutate({
          mutation: collapseSdkLocationCard,
          variables: {
            input: {
              cardId,
              collapsed,
              objectTypeId
            }
          }
        }).catch(e => {
          Raven.captureException(new Error('useCrmLocationService.updateIsCardCollapsed: Error saving new state'), {
            extra: {
              originalError: {
                message: e.message,
                name: e.name
              },
              variables: {
                cardId,
                collapsed,
                objectTypeId
              }
            }
          });
        });
      };
    }
    return undefined;
  }, [additionalLocationData.objectId, additionalLocationData.objectTypeId, apolloClient, dataFetchingClient, location]);
  const updateCardOrder = useCallback(orderedCardIds => {
    updateCachedCardOrder(orderedCardIds);
    updateCardOrderMutation({
      variables: {
        orderedCardIds
      }
    }).then(() => {
      if (onUpdateCardOrder != null) {
        onUpdateCardOrder(orderedCardIds);
      }
    }).catch(e => {
      Raven.captureException(new Error('useCrmLocationService.updateCardOrder: Error saving new state'), {
        extra: {
          originalError: {
            message: e.message,
            name: e.name
          },
          variables: {
            orderedCardIds
          }
        }
      });
    });
  }, [onUpdateCardOrder, updateCardOrderMutation, updateCachedCardOrder]);
  const service = useMemo(() => {
    return {
      updateIsCardCollapsed,
      updateCardOrder
    };
  }, [updateCardOrder, updateIsCardCollapsed]);
  return service;
};