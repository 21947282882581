/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { useContext, useEffect, useState } from 'react';
import enviro from 'enviro';
import { fetchCsatSurvey } from '../api/surveyApi';
import SurveyConfigContext from '../context/SurveyConfigContext';
import { SURVEY_CONFIG_STATUS } from '../constants/surveyConstants';
const hsq = window._hsq = window._hsq || [];
const getSurveyPortalId = () => {
  const isQa = enviro.isQa();
  return isQa ? 100765651 : 53;
};
export const getFeedbackIgnoreKey = (surveyId, surveyType) => `FEEDBACK_IGNORED&${surveyType}&${surveyId}&${getSurveyPortalId()}`;
export const hideSurvey = (feedbackIgnoreKey, days = 14) => {
  const expiry = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
  try {
    localStorage.setItem(feedbackIgnoreKey, expiry.toISOString());
  } catch (error) {
    return;
  }
};
export const isSurveyHidden = (surveyId, surveyType) => {
  const feedbackIgnoreKey = getFeedbackIgnoreKey(surveyId, surveyType);
  let item;
  try {
    item = localStorage.getItem(feedbackIgnoreKey);
  } catch (error) {
    return false;
  }
  if (!item) return false;
  if (new Date(item) > new Date()) {
    return true;
  }
  try {
    localStorage.removeItem(feedbackIgnoreKey);
  } catch (error) {
    return false;
  }
  return false;
};

/**
 * Load analytics js file for tracking feedback surveyed count
 * https://git.hubteam.com/HubSpot/feedback-web-renderer-ui/blob/0d683253e391cc3361748f7ce765d5d934799202/feedback-loader/static/js/index.js#L52-L57
 */
const loadAnalyticsJS = () => {
  if (document.getElementById('analytics-js')) {
    return;
  }
  const isQa = enviro.isQa();
  const qaSuffix = isQa ? 'qa' : '';
  const analyticsSrc = `//js.hubspot${qaSuffix}.com/analytics/0/common.js`;
  const script = document.createElement('script');
  script.setAttribute('id', 'analytics-js');
  script.setAttribute('async', 'true');
  script.setAttribute('src', analyticsSrc);
  document.body.appendChild(script);
};
export const useHideSurvey = surveyConfig => {
  useEffect(() => {
    if (surveyConfig && surveyConfig.config) {
      const {
        config
      } = surveyConfig;
      const key = getFeedbackIgnoreKey(config.surveyId, config.surveyType);
      hideSurvey(key);
    }
  }, [surveyConfig]);
};
export const useSurveyConfig = (surveyId, surveyType) => {
  const [surveyConfig, setSurveyConfig] = useState({
    status: SURVEY_CONFIG_STATUS.NOT_FETCHED
  });
  const surveyConfigContext = useContext(SurveyConfigContext);
  useEffect(() => {
    if (isSurveyHidden(surveyId, surveyType)) {
      setSurveyConfig({
        status: SURVEY_CONFIG_STATUS.FETCHED
      });
      return;
    }
    if (surveyConfigContext && surveyConfigContext.config) {
      setSurveyConfig(surveyConfigContext);
    } else {
      setSurveyConfig({
        status: SURVEY_CONFIG_STATUS.FETCHING
      });
      /* hs-eslint ignored failing-rules */
      /* error has been caught in fetchCsatSurvey */
      /* eslint-disable-next-line promise/catch-or-return */
      fetchCsatSurvey(surveyType, surveyId).then(response => {
        setSurveyConfig(Object.assign({}, response, {
          status: SURVEY_CONFIG_STATUS.FETCHED
        }));
      });
    }
  }, [surveyConfigContext, surveyId, surveyType]);
  return [surveyConfig, setSurveyConfig];
};
export const useTrackSurveyView = surveyConfig => {
  useEffect(() => {
    if (surveyConfig && surveyConfig.config) {
      const {
        surveyId,
        surveyType
      } = surveyConfig.config;
      loadAnalyticsJS();
      hsq.push(['trackFeedbackView', {
        surveyId,
        surveyType
      }]);
    }
  }, [surveyConfig]);
};