module.exports = {
  "embedPointsPageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": "string",
      "displayType": "string",
      "toolStatus": "array"
    },
    "namespace": "payments-embed-points",
    "version": "1"
  },
  "embedPointsInteraction": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "screen": "string",
      "action": [
        "hover",
        "click"
      ]
    },
    "namespace": "payments-embed-points",
    "version": "1"
  },
  "paymentsInterstitialModalInteraction": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "screen": "string",
      "action": [
        "click"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "payments-embed-points",
    "version": "1"
  },
  "paymentsInterstitialModalView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "displayType": "string",
      "subtype": [
        "paymentsInterstitialModal"
      ]
    },
    "namespace": "payments-embed-points",
    "version": "1"
  }
};