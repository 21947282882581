import { PRE_APPROVED, KYC_EMBEDDED_FALLBACK_EXPERIENCE, COMMERCE_HOME_PRE_TOOL_START } from '../constants/gates';
import { useSet } from '../set/useSet';
import { useAuth, getAuth } from './user';
import get from 'growth-payments-core/object/get';
export const useGates = () => {
  const [userInfo] = useAuth();
  const gatesSet = useSet(get(userInfo, 'gates'));
  return gatesSet;
};
export const getGates = () => {
  return get(getAuth(), 'gates');
};
export const useIsUngated = (...gatesToCheck) => {
  const gates = useGates();
  return gatesToCheck.every(gate => gates.has(gate));
};
export const useIsUngatedForPreApproval = () => useIsUngated(PRE_APPROVED);
export const useIsUngatedForKycEmbeddedFallbackExperience = () => useIsUngated(KYC_EMBEDDED_FALLBACK_EXPERIENCE);
export const useIsUngatedForCommerceHomePreToolStart = () => useIsUngated(COMMERCE_HOME_PRE_TOOL_START);