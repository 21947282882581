export const EXPERIMENTAL_LAYOUTS = ['UIGRID', 'COLUMNS', 'GRID'];
export const isExperimentalLayout = layoutType => {
  return EXPERIMENTAL_LAYOUTS.includes(layoutType);
};
const COLLAPSIBLE_LAYOUTS = ['GRID', 'LIST', 'UIGRID'];
export const layoutSupportsCollapsing = layout => {
  return COLLAPSIBLE_LAYOUTS.includes(layout.type);
};
export const CRM_CONTAINER_LAYOUT_TYPES = {
  GRID: 'GRID',
  COLUMNS: 'COLUMNS',
  TABS: 'TABS',
  LIST: 'LIST',
  SINGLE_CARD: 'SINGLE_CARD',
  STACK: 'STACK',
  UIGRID: 'UIGRID'
};