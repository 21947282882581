import http from 'hub-http/clients/apiClient';
export const triggerGrowthModal = params => http.post('growth-modals/v1/modals/events', {
  data: params
});
export const deleteGrowthModalDismissalForPortal = modalName => {
  const resp = http.delete(`growth-modals/v1/modals/dismissal/for-portal/${modalName}`);
  return resp;
};

/**
 * @description Dimisses a growth modal for a portal.
 * @param modalName Name of the growth modal
 */
export const dismissGrowthModalForPortal = modalName => http.delete(`growth-modals/v1/modals/for-portal/${modalName}`);

/**
 * @description Dimisses a growth modal for a user in a portal.
 * @param modalName Name of the growth modal
 */
export const dismissGrowthModalForUser = modalName => http.delete(`growth-modals/v1/modals/for-user/${modalName}`);