let _ = t => t,
  _t;
import { createGlobalStyle } from 'styled-components';
import { useTokens } from './useTokens';
export const GlobalStyle = createGlobalStyle(_t || (_t = _`
${0};
`), () => {
  const {
    tokens
  } = useTokens();
  return `
  :root {
    --layout-lib-background-color: ${tokens.backgroundColor.default};
    --layout-lib-background-color-light: ${tokens.backgroundColor.light};
    --layout-lib-background-color-dark: ${tokens.backgroundColor.dark};
    --layout-lib-background-color-breeze: ${tokens.breezeBrand.default};
    --layout-lib-background-color-breeze-light: ${tokens.breezeBrand.light};
    --layout-lib-background-color-breeze-extra-light: ${tokens.breezeBrand.extraLight};
    --layout-lib-background-color-breeze-extra-extra-light: ${tokens.breezeBrand.extraExtraLight};
    --layout-lib-border-color: ${tokens.borderColor.default};
    --layout-lib-border-color-light: ${tokens.borderColor.light};
    --layout-lib-border-color-dark: ${tokens.borderColor.dark};
    --layout-lib-border: var(--layout-lib-border-width) solid var(--layout-lib-border-color);
    --layout-lib-border-dark: var(--layout-lib-border-width) solid var(--layout-lib-border-color-dark);
    --layout-lib-border-light: var(--layout-lib-border-width) solid var(--layout-lib-border-color-light);
    --layout-lib-border-transparent: var(--layout-lib-border-width) solid transparent;
    --layout-lib-border-none: 0;
    --layout-lib-border-radius-sm: ${tokens.borderRadius.sm};
    --layout-lib-border-radius: ${tokens.borderRadius.default};
    --layout-lib-border-radius-md: ${tokens.borderRadius.md};
    --layout-lib-border-radius-lg: ${tokens.borderRadius.lg};
    --layout-lib-border-radius-xl: ${tokens.borderRadius.xl};
    --layout-lib-border-radius-xxl: ${tokens.borderRadius.xxl};
    --layout-lib-border-radius-full: ${tokens.borderRadius.full};
    --layout-lib-border-width: ${tokens.borderWidth.default};
    --layout-lib-border-width-double: ${tokens.borderWidth.double};
    --layout-lib-gradient-breeze-dark: ${tokens.breezeBrand.gradient.dark};
    --layout-lib-gradient-breeze-light: ${tokens.breezeBrand.gradient.light};
    --layout-lib-gradient-breeze-medium: ${tokens.breezeBrand.gradient.medium};
    --layout-lib-shadow-card: ${tokens.shadow.card};
    --layout-lib-space-xxxxs: ${tokens.space.xxxxs};
    --layout-lib-space-4xs: ${tokens.space.xxxxs};
    --layout-lib-space-xxxs: ${tokens.space.xxxs};
    --layout-lib-space-3xs: ${tokens.space.xxxs};
    --layout-lib-space-xxs: ${tokens.space.xxs};
    --layout-lib-space-2xs: ${tokens.space.xxs};
    --layout-lib-space-xs: ${tokens.space.xs};
    --layout-lib-space-sm: ${tokens.space.sm};
    --layout-lib-space: ${tokens.space.default};
    --layout-lib-space-md: ${tokens.space.md};
    --layout-lib-space-lg: ${tokens.space.lg};
    --layout-lib-space-xl: ${tokens.space.xl};
    --layout-lib-space-xxl: ${tokens.space.xxl};
    --layout-lib-space-2xl: ${tokens.space.xxl};
    --layout-lib-space-xxxl: ${tokens.space.xxxl};
    --layout-lib-space-3xl: ${tokens.space.xxxl};
    --layout-lib-space-xxxxl: ${tokens.space.xxxxl};
    --layout-lib-space-4xl: ${tokens.space.xxxxl};
    --layout-lib-negative-space-xxxxs: -${tokens.space.xxxxs};
    --layout-lib-negative-space-4xs: -${tokens.space.xxxxs};
    --layout-lib-negative-space-xxxs: -${tokens.space.xxxs};
    --layout-lib-negative-space-3xs: -${tokens.space.xxxs};
    --layout-lib-negative-space-xxs: -${tokens.space.xxs};
    --layout-lib-negative-space-2xs: -${tokens.space.xxs};
    --layout-lib-negative-space-xs: -${tokens.space.xs};
    --layout-lib-negative-space-sm: -${tokens.space.sm};
    --layout-lib-negative-space: -${tokens.space.default};
    --layout-lib-negative-space-md: -${tokens.space.md};
    --layout-lib-negative-space-lg: -${tokens.space.lg};
    --layout-lib-negative-space-xl: -${tokens.space.xl};
    --layout-lib-negative-space-xxl: -${tokens.space.xxl};
    --layout-lib-negative-space-2xl: -${tokens.space.xxl};
    --layout-lib-negative-space-xxxl: -${tokens.space.xxxl};
    --layout-lib-negative-space-3xl: -${tokens.space.xxxl};
    --layout-lib-negative-space-xxxxl: -${tokens.space.xxxxl};
    --layout-lib-negative-space-4xl: -${tokens.space.xxxxl};
    --layout-lib-text-color: ${tokens.textColor.base};
    --layout-lib-text-color-base: ${tokens.textColor.base};
    --layout-lib-text-color-danger: ${tokens.textColor.danger};
    --layout-lib-text-color-disabled: ${tokens.textColor.disabled};
    --layout-lib-text-color-extra-light: ${tokens.textColor.extraLight};
    --layout-lib-text-color-light: ${tokens.textColor.light};
    --layout-lib-text-color-inverse: ${tokens.textColor.inverse};
    --layout-lib-breeze-brand: ${tokens.breezeBrand.default};
  `;
});