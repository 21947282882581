// UIIFrame message MESSAGE_TYPE for sending messages to the host
export const RELAY_IFRAME_MESSAGE_TYPE = 'CRM_MESSAGE_QUEUE_RELAY_FROM_IFRAME';

// UIIFrame message MESSAGE_TYPE for receiving message from the host
export const CRM_MESSAGE_QUEUE_RELAY = 'CRM_MESSAGE_QUEUE_RELAY';

// UIIFrame message MESSAGE_TYPE for receiving message from the host.
// NOTE: this has continued to have the QUEUE in the string because during the migration
// it is possible that the iframe will not be released at the same time to pick up a new string.
// And this actual string is irrelivant to anything.
export const CRM_MESSAGE_BUS_RELAY = 'CRM_MESSAGE_QUEUE_RELAY';

//  Topic for messages that are coming from the standard dispatch mechanism
export const DISPATCHED_TOPIC = 'DISPATCHED_EVENT';