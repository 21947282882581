import { useLazyQuery } from 'data-fetching-client';
import { navigateTo } from 'growth-payments-core/urls/utils';
import { getCommerceHomeUrl } from 'growth-payments-core/urls/urls';
import { GET_PAYMENTS_SETTINGS_CONNECTED_ACCOUNT } from '../queries/connectedAccountQueries';
import { GET_COMMERCE_HOME_ACCOUNT_ELIGIBILITY } from '../queries/paymentAccountQueries';

/** This hook is used to update the parent apps's(settings-ui-payments) cache with the updated values for Eligibility and Connected-accounts after a successful OAuth connection is complete.
 *
 *  After an OAuth connection,if the user is in the zero-state, updating eligibility will force a re-render from the zero state that will load the guide tab and fetch
 *  payments-account/default without having the user to refresh the page.
 *
 *  If the user is in the post-enrollment disconnected state, after reconnecting, refetching connected-accounts will hide the disconnected account alert.
 *  To keep the parent cache up-to-date we call both APIs irrespective of whether they are called from the zero state or the post-enrollment disconnected state.
 */

const useRefetchAfterOAuthOnboardingCompletion = () => {
  // IMPORTANT: The GET_PAYMENTS_SETTINGS_ELIGIBILITY query fieldName must match the fieldName in settings-ui-payments.
  const [refetchEligibility, {
    loading: eligibilityLoading
  }] = useLazyQuery(GET_COMMERCE_HOME_ACCOUNT_ELIGIBILITY, {
    fetchPolicy: 'network-only',
    onError: () => navigateTo(getCommerceHomeUrl())
  });
  // IMPORTANT: The GET_PAYMENTS_SETTINGS_CONNECTED_ACCOUNT query fieldName must match the fieldName in settings-ui-payments.
  const [refetchConnectedAccount, {
    loading: connectedAccountLoading
  }] = useLazyQuery(GET_PAYMENTS_SETTINGS_CONNECTED_ACCOUNT, {
    fetchPolicy: 'network-only',
    onError: error => {
      throw error;
    }
  });
  return {
    refetch: () => {
      refetchEligibility();
      refetchConnectedAccount();
    },
    loading: connectedAccountLoading || eligibilityLoading
  };
};
export default useRefetchAfterOAuthOnboardingCompletion;