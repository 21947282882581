import { CALL_TYPE_ID, COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, MEETING_EVENT_TYPE_ID, TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { registerQuery, useQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
export const GET_ASSOCIATIONS_AUDIT_SOURCES = registerQuery({
  fieldName: 'AssociationAuditSources',
  args: ['associationSpecs', 'fromObjectId'],
  fetcher({
    associationSpecs,
    fromObjectId
  }) {
    return http.post(`associations/v1/audits/user-facing/${fromObjectId}`, {
      data: associationSpecs
    }).then(response => {
      return response.map(audit => ({
        toObjectId: audit.toObjectId,
        sourceMetadata: {
          source: audit.sourceMetadata.source || null,
          sourceId: audit.sourceMetadata.sourceId || null
        }
      }));
    });
  }
});
const AUDIT_SOURCE_FETCH_LIST = [MEETING_EVENT_TYPE_ID, CONTACT_TYPE_ID, COMPANY_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID];
export function shouldFetchAuditSourcesForObjectTypes(objectTypeId, toObjectTypeId) {
  return objectTypeId === CALL_TYPE_ID && AUDIT_SOURCE_FETCH_LIST.includes(toObjectTypeId);
}
export function useFetchAssociationAuditSources({
  associationSpecs,
  objectId,
  objectTypeId,
  toObjectTypeId,
  skip = false
}) {
  return useQuery(GET_ASSOCIATIONS_AUDIT_SOURCES, {
    variables: {
      associationSpecs,
      fromObjectId: objectId
    },
    skip: skip || !shouldFetchAuditSourcesForObjectTypes(objectTypeId, toObjectTypeId) || !associationSpecs || !associationSpecs.length
  });
}