import apiClient from 'hub-http/clients/apiClient';
import { catchAndRethrowNetworkError
// @ts-expect-error dependency missing types
} from 'crm_data/bCommerce/monitoring/errorUtils';
import { OAUTH_ACCOUNT_ALREADY_CONNECTED, OAUTH_UNSUPPORTED_COUNTRY } from '../constants/networkErrors';
const GET_OAUTH_SESSION_LATEST_STATUS_API = 'payments-accounts-rest/v1/oauth-sessions/latest-status-by-portal';
/**
 * We need this API because Stripe OAuth onboarding and payment account creation happens asynchronously on the BE.
 * This will allow the FE to query how the latest OAuth attempt is progressing or ended.
 *
 * If you receive a 200, the account is successfully connected. Response will be SUCCESSFULLY_CONNECTED
 * If you receive a 202, the backend is in a pending state. Response will be either WAITING_CONNECTION OR FAILED_CONNECTING_RETRYING.
 * If you receive a 400, the onboarding has failed. There can be a number of reasons for the failure listed
 */

export const getStripeOAuthSessionLatestStatus = () => apiClient.get(GET_OAUTH_SESSION_LATEST_STATUS_API).catch(error => {
  const errorSubcategory = error.data && JSON.parse(error.data).subCategory;
  if (errorSubcategory === OAUTH_ACCOUNT_ALREADY_CONNECTED || errorSubcategory === OAUTH_UNSUPPORTED_COUNTRY) {
    // We are handling these errors with user-friendly alerts in hooks/useOAuthSessionLatestStatus. We do not want to report these.
    throw error;
  } else {
    catchAndRethrowNetworkError(error);
  }
});