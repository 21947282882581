export const doAndRethrow = cb => err => {
  if (cb) {
    cb(err);
  }
  throw err;
};
export function doAndPassthrough(cb) {
  return arg => {
    cb(arg);
    return arg;
  };
}