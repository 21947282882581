import { useEffect, useState } from 'react';
import { useToolUsageBinding } from '../../api/ToolUsageApi';
export const CommerceToolNames = {
  invoices: 'invoices',
  paymentLinks: 'paymentLinks',
  quotes: 'quotes',
  subscriptions: 'subscriptions'
};
const objectTypeIdToToolName = {
  '0-14': CommerceToolNames.quotes,
  '0-53': CommerceToolNames.invoices,
  '0-69': CommerceToolNames.subscriptions,
  '0-118': CommerceToolNames.paymentLinks
};
export const ToolStatus = {
  PRE_TOOL: 'pre_tool',
  POST_TOOL: 'post_tool'
};
const parseUsageMetrics = usageMetrics => {
  const toolUsage = {};
  if (!usageMetrics) {
    return toolUsage;
  }
  usageMetrics.forEach(metric => {
    if (metric.objectTypeId in objectTypeIdToToolName) {
      const toolName = objectTypeIdToToolName[metric.objectTypeId];
      toolUsage[toolName] = !metric.hasCreatedObject ? ToolStatus.PRE_TOOL : ToolStatus.POST_TOOL;
    }
  });
  return toolUsage;
};
const useCommerceToolStatus = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [toolUsageResponse] = useToolUsageBinding();
  useEffect(() => {
    if (toolUsageResponse) {
      setIsLoading(false);
    }
  }, [toolUsageResponse]);
  if (!toolUsageResponse) {
    return {
      toolUsage: null,
      isLoading
    };
  }
  const toolUsage = parseUsageMetrics(toolUsageResponse.usageMetrics);
  return {
    toolUsage,
    isLoading
  };
};
export default useCommerceToolStatus;