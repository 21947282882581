export const CRM_LOCATION_NAMES = {
  ACCOUNT_PREVIEW: 'ACCOUNT_PREVIEW',
  ACCOUNT_PREVIEW_TABBED: 'ACCOUNT_PREVIEW_TABBED',
  BASIC_SIDEBAR: 'BASIC_SIDEBAR',
  CONVERSATIONS_INBOX: 'CONVERSATIONS_INBOX',
  CRM_CONTAINER_MIDDLE: 'CRM_CONTAINER_MIDDLE',
  CRM_OBJECT_PREVIEW: 'CRM_OBJECT_PREVIEW',
  CRM_PREVIEW: 'CRM_PREVIEW',
  CRM_RECORD_LEFT: 'CRM_RECORD_LEFT',
  CRM_RECORD_MIDDLE: 'CRM_RECORD_MIDDLE',
  CRM_RECORD_OVERVIEW: 'CRM_RECORD_OVERVIEW',
  CRM_RECORD_RIGHT: 'CRM_RECORD_RIGHT',
  CUSTOM: 'CUSTOM',
  CUSTOMER_SUCCESS_SIDEBAR: 'CUSTOMER_SUCCESS_SIDEBAR',
  DEAL_EXECUTION_SIDEBAR: 'DEAL_EXECUTION_SIDEBAR',
  FLYWHEEL_PROSPECTING_SIDEBAR: 'FLYWHEEL_PROSPECTING_SIDEBAR',
  GUIDED_EXECUTION: 'GUIDED_EXECUTION',
  HELPDESK_SIDEBAR: 'HELPDESK_SIDEBAR',
  INDEX_UI_DATAWELL: 'INDEX_UI_DATAWELL',
  INTEGRATION_EMBED: 'INTEGRATION_EMBED',
  SALES_ACTIVITIES: 'SALES_ACTIVITIES',
  TEST_LOCATION_FOR_FAS_CARD_TYPES: 'TEST_LOCATION_FOR_FAS_CARD_TYPES',
  TEST_LOCATION_FOR_LAYOUTS: 'TEST_LOCATION_FOR_LAYOUTS',
  PROSPECTING_LEFT: 'PROSPECTING_LEFT',
  PROSPECTING_RIGHT: 'PROSPECTING_RIGHT',
  CUSTOMER_SUCCESS_ALERTS_SIDEBAR: 'CUSTOMER_SUCCESS_ALERTS_SIDEBAR'
};
export const SIDEBAR_LOCATIONS = [CRM_LOCATION_NAMES.BASIC_SIDEBAR, CRM_LOCATION_NAMES.CONVERSATIONS_INBOX, CRM_LOCATION_NAMES.CRM_OBJECT_PREVIEW, CRM_LOCATION_NAMES.CRM_PREVIEW, CRM_LOCATION_NAMES.CRM_RECORD_LEFT, CRM_LOCATION_NAMES.CRM_RECORD_RIGHT, CRM_LOCATION_NAMES.CUSTOMER_SUCCESS_SIDEBAR, CRM_LOCATION_NAMES.FLYWHEEL_PROSPECTING_SIDEBAR, CRM_LOCATION_NAMES.HELPDESK_SIDEBAR, CRM_LOCATION_NAMES.SALES_ACTIVITIES, CRM_LOCATION_NAMES.INTEGRATION_EMBED, CRM_LOCATION_NAMES.CUSTOMER_SUCCESS_ALERTS_SIDEBAR];
export const CRM_LOCATION_QUERY_LOCATIONS = [CRM_LOCATION_NAMES.ACCOUNT_PREVIEW, CRM_LOCATION_NAMES.ACCOUNT_PREVIEW_TABBED, CRM_LOCATION_NAMES.CRM_CONTAINER_MIDDLE, CRM_LOCATION_NAMES.CRM_OBJECT_PREVIEW, CRM_LOCATION_NAMES.FLYWHEEL_PROSPECTING_SIDEBAR, CRM_LOCATION_NAMES.HELPDESK_SIDEBAR, CRM_LOCATION_NAMES.CRM_RECORD_LEFT, CRM_LOCATION_NAMES.CRM_RECORD_RIGHT, CRM_LOCATION_NAMES.CRM_RECORD_MIDDLE, CRM_LOCATION_NAMES.CUSTOMER_SUCCESS_SIDEBAR, CRM_LOCATION_NAMES.PROSPECTING_LEFT, CRM_LOCATION_NAMES.PROSPECTING_RIGHT, CRM_LOCATION_NAMES.TEST_LOCATION_FOR_LAYOUTS, CRM_LOCATION_NAMES.DEAL_EXECUTION_SIDEBAR, CRM_LOCATION_NAMES.GUIDED_EXECUTION, CRM_LOCATION_NAMES.CUSTOMER_SUCCESS_ALERTS_SIDEBAR];
export const RECORD_CARDS_QUERY_LOCATIONS = [CRM_LOCATION_NAMES.BASIC_SIDEBAR, CRM_LOCATION_NAMES.CONVERSATIONS_INBOX, CRM_LOCATION_NAMES.CRM_PREVIEW, CRM_LOCATION_NAMES.CRM_RECORD_OVERVIEW, CRM_LOCATION_NAMES.SALES_ACTIVITIES, CRM_LOCATION_NAMES.INTEGRATION_EMBED];
export const FAS_QUERY_LOCATIONS = [CRM_LOCATION_NAMES.TEST_LOCATION_FOR_FAS_CARD_TYPES];
export function isRecordCardQueryLocation(location) {
  return RECORD_CARDS_QUERY_LOCATIONS.includes(location);
}
export function isCrmLocationQueryLocation(location) {
  return CRM_LOCATION_QUERY_LOCATIONS.includes(location);
}
export function isFasLocation(location) {
  return FAS_QUERY_LOCATIONS.includes(location);
}
export function isSidebarLocation(location) {
  return SIDEBAR_LOCATIONS.includes(location);
}