import { catchAndRethrowNetworkError } from 'growth-onboarding-reliability/utils/raven';

/**
 * Handles API errors related to payment account and either resolves the expected errors or rethrows the error.
 * Some APIs are expected to fail when there is either no active payment account or an active but disconnected account.
 * @example
 * `PORTAL_DOES_NOT_HAVE_ACCOUNT` --> no active connected account. This happens in the zero state until an account is created.
 * `ACCOUNT_DOES_NOT_EXIST` --> there is an active account associated but it has been disconnected. We need to ignore this error for the reconnection flow.
 * `UNDERWRITING_COMPANY_RECORD_NOT_FOUND` --> thrown by the GET underwriting endpoint when no contact record was found for the company.
 *
 * @param error - The API error object.
 * @param expectedErrors - An array of expected error codes.
 * @returns null if the error is one of the expected errors, otherwise rethrows the error.
 */
export const resolveExpectedPaymentAccountErrorOrRethrow = (error, expectedErrors) => {
  if (error.data && expectedErrors.includes(JSON.parse(error.data).subCategory.replace('PaymentAccountError.', ''))) {
    return null;
  }
  return catchAndRethrowNetworkError(error);
};