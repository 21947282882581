import { Loadable } from 'foundations-components/transitional/utils/Loadable';
import { AsyncComponentErrorHandler } from 'crm-events-components/internal/helpers/AsyncComponentErrorHandler';
import styled from 'styled-components';
const StyledDiv = styled.div.withConfig({
  displayName: "TimelineTabsCreateEngagementButtonsAsync__StyledDiv",
  componentId: "sc-1h6soxv-0"
})(["&&{height:40px !important;}"]);
const TimelineTabsCreateEngagementButtonsAsync = Loadable({
  loader: () => import( /* webpackChunkName: "timeline-tabs-create-engagement-buttons" */'./TimelineTabsCreateEngagementButtons').then(mod => mod.default),
  LoadingComponent: StyledDiv,
  ErrorComponent: AsyncComponentErrorHandler('timeline-tabs-create-engagement-buttons')
});
export default TimelineTabsCreateEngagementButtonsAsync;