import { registerQuery } from 'data-fetching-client';
import { getConnectedAccount } from '../api/ConnectedAccounts';
export const GET_CONNECTED_ACCOUNT = registerQuery({
  fieldName: 'connectedAccount',
  fetcher: getConnectedAccount
});

// IMPORTANT: This GET_PAYMENTS_SETTINGS_CONNECTED_ACCOUNT fieldName must match the
// fieldName in settings-ui-payments exactly to ensure cache updates propagate
// to the parent app.
// (https://git.hubteam.com/HubSpotProtected/settings-ui-payments/blob/master/settings-ui-payments/static/js/data/connectedAccountQueries.ts)
export const GET_PAYMENTS_SETTINGS_CONNECTED_ACCOUNT = registerQuery({
  fieldName: 'connectedAccount',
  fetcher: getConnectedAccount
});