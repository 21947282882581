import { buildCrmCardRegistry } from 'crm-cards/buildCrmCardRegistry';
import { DEFAULT_LOADING_COMPONENT_PROPS } from 'crm-cards-sdk/constants/LoadingComponentProps';
import { getLoadingComponentProps as getLoadingComponentPropsAOC } from 'card-associated-objects-lib/localStorage/skeletonState/getLoadingComponentProps';
import RecordTimelineCard from '../middlePane/RecordTimelineCard';
import PropertiesCard from 'card-properties-lib/sdk/PropertiesCard';
import AssociatedObjectsCardSdk from 'card-associated-objects-lib/AssociatedObjectsCardSdk';
export function buildRecordPageCardRegistry() {
  const defaultRegistry = buildCrmCardRegistry();
  return Object.assign({}, defaultRegistry, {
    getCardComponent: cardType => {
      if (cardType === 'RECORD_TIMELINE') {
        // HACK: The types for registries are slightly incorrect so we have to
        // type cast here
        return RecordTimelineCard;
      } else if (cardType === 'PROPERTIES_V3') {
        return PropertiesCard;
      } else if (cardType === 'ASSOCIATION_V3') {
        return AssociatedObjectsCardSdk;
      }
      return defaultRegistry.getCardComponent(cardType);
    },
    getLazyLoadedTranslations: cardType => {
      if (cardType === 'RECORD_TIMELINE' || cardType === 'PROPERTIES_V3' || cardType === 'ASSOCIATION_V3') {
        return undefined;
      }
      return defaultRegistry.getLazyLoadedTranslations(cardType);
    },
    getLoadingComponentProps: (cardType, cardData, ...args) => {
      if (cardType === 'RECORD_TIMELINE') {
        return DEFAULT_LOADING_COMPONENT_PROPS;
      }
      if (cardType === 'ASSOCIATION_V3') {
        return getLoadingComponentPropsAOC(cardData, ...args);
      }
      return defaultRegistry.getLoadingComponentProps(cardType, cardData, ...args);
    }
  });
}