import styled from 'foundations-theming/utils';
const SkeletonLink = styled.div.withConfig({
  displayName: "SkeletonLink",
  componentId: "sc-12slyq9-0"
})(["width:", "px;background-color:", ";border-radius:", ";display:inline-block;height:0.8em;margin:0.4em 0;"], ({
  width = 120
}) => width, ({
  theme
}) => theme.color['do-not-use-koala'], ({
  theme
}) => theme.borderRadius['100']);
export default SkeletonLink;