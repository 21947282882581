import { useQuery } from 'data-fetching-client';
import { calculateKYCStatus, shouldShowZeroState } from 'growth-payments-core/eligibility/utils';
import { GET_CONNECTED_ACCOUNT } from '../queries/connectedAccountQueries';
import { GET_PAYMENTS_ELIGIBILITY } from '../queries/paymentAccountQueries';
import { ProcessorType } from 'growth-payments-core/eligibility/types';
import { Scopes, getScopes, hasScope } from 'growth-payments-core/auth/scopes';
const useCanOpenPaymentsEnrollment = () => {
  const {
    data: eligibilityData,
    loading: loadingEligibility
  } = useQuery(GET_PAYMENTS_ELIGIBILITY);
  const {
    data: connectedAccountData,
    loading: loadingConnectedAccount
  } = useQuery(GET_CONNECTED_ACCOUNT);
  const kycStatus = eligibilityData ? calculateKYCStatus(eligibilityData.paymentsEnrollmentEmbedEligibility.underwritingStatus) : null;
  const defaultProcessor = eligibilityData === null || eligibilityData === void 0 ? void 0 : eligibilityData.paymentsEnrollmentEmbedEligibility.processorType;
  const scopes = getScopes() || [];
  const hasPaymentsEligibleScope = hasScope(Scopes.paymentsEligible, scopes);
  const hasNoConnectedPaymentsAccount = !!eligibilityData && shouldShowZeroState(kycStatus) || !!connectedAccountData && !!connectedAccountData.connectedAccount && !connectedAccountData.connectedAccount.isEnabled;

  // connectedAccount.isEnabled will still be true in the downgraded case
  const isDowngradedPaymentsPortal = !hasPaymentsEligibleScope && defaultProcessor === ProcessorType.HS_PAYMENTS;
  return {
    loading: loadingConnectedAccount || loadingEligibility,
    canOpenPaymentsEnrollment: hasNoConnectedPaymentsAccount || isDowngradedPaymentsPortal
  };
};
export default useCanOpenPaymentsEnrollment;