export let ModalDisplay;
(function (ModalDisplay) {
  ModalDisplay["CRM"] = "CRM";
  ModalDisplay["PAYMENT_LINK"] = "PAYMENT_LINK";
  ModalDisplay["NONE"] = "NONE";
  ModalDisplay["COLLECT_PAYMENTS"] = "COLLECT_PAYMENTS";
  ModalDisplay["COLLECT_WITH_PAYMENT_LINKS"] = "COLLECT_WITH_PAYMENT_LINKS";
  ModalDisplay["COLLECT_WITH_SUBSCRIPTIONS"] = "COLLECT_WITH_SUBSCRIPTIONS";
  ModalDisplay["COLLECT_WITH_INVOICES"] = "COLLECT_WITH_INVOICES";
  ModalDisplay["ENROLL_WITH_INVOICES"] = "ENROLL_WITH_INVOICES";
  ModalDisplay["CREATE_A_PAYMENT_LINK_PRE_ENROLL"] = "CREATE_A_PAYMENT_LINK_PRE_ENROLL";
  ModalDisplay["CREATE_A_PAYMENT_LINK_POST_ENROLL"] = "CREATE_A_PAYMENT_LINK_POST_ENROLL";
  ModalDisplay["ENROLL_WITH_PAYMENT_LINKS"] = "ENROLL_WITH_PAYMENT_LINKS";
})(ModalDisplay || (ModalDisplay = {}));