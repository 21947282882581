import { useSurveyConfig } from '../utils/surveyUtils';
import { SURVEY_CONFIG_STATUS } from '../constants/surveyConstants';
export const useFeedbackSurveyConfig = ({
  surveyId,
  surveyType
}) => {
  const {
    FETCHED
  } = SURVEY_CONFIG_STATUS;
  const [surveyConfig, setSurveyConfig] = useSurveyConfig(surveyId, surveyType);
  const {
    status
  } = surveyConfig || {};
  return {
    loading: status !== FETCHED,
    surveyConfig,
    setSurveyConfig
  };
};
export default useFeedbackSurveyConfig;