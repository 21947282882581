import { useState } from 'react';
import useOnboardingRedirect from './useOnboardingRedirect';
import { Metrics } from '../utils/metrics';
import { navigateTo } from 'growth-payments-core/urls/utils';
import { getCommerceHomeUrl } from 'growth-payments-core/urls/urls';
import { GET_PAYMENT_ACCOUNT, GET_PAYMENT_ACCOUNT_PERSONS } from '../queries/paymentAccountQueries';
import { useLazyQuery } from 'data-fetching-client';
import { getHasPendingRequirements } from '../utils/requirements';
export const useFallbackModalRedirect = ({
  businessType,
  businessStructure
}) => {
  const {
    loading,
    error,
    redirect
  } = useOnboardingRedirect();
  const [isPaymentAccountError, setIsPaymentAccountError] = useState(false);
  const [fetchPaymentAccount, {
    loading: paymentAccountLoading
  }] = useLazyQuery(GET_PAYMENT_ACCOUNT, {
    fetchPolicy: 'network-only',
    onError: () => setIsPaymentAccountError(true)
  });
  const [fetchPaymentAccountPersons, {
    loading: paymentPersonsLoading
  }] = useLazyQuery(GET_PAYMENT_ACCOUNT_PERSONS, {
    fetchPolicy: 'network-only',
    onError: () => setIsPaymentAccountError(true)
  });

  // Fetch account data one final time to see if pending requirements have been
  // verified since the KYC was submitted
  return {
    redirect: () => {
      Promise.all([fetchPaymentAccount(), fetchPaymentAccountPersons()]).then(([accountData, personsData]) => {
        const hasAccountRequirementsDue = getHasPendingRequirements({
          currentlyDue: accountData.filteredCurrentlyDue,
          pastDue: accountData.filteredPastDue
        });
        const hasPersonsRequirementsDue = personsData.some(person => person.requirements.currentlyDue.length || person.requirements.pastDue.length);
        const hasRequirementsDue = hasAccountRequirementsDue || hasPersonsRequirementsDue;
        if (hasRequirementsDue) {
          return redirect();
        }
        if (businessType) {
          Metrics.counter('fallback-modal-no-requirements', {
            businessType,
            businessStructure: businessStructure || ''
          }).increment();
        }
        return navigateTo(getCommerceHomeUrl());
      }).catch(() => {
        return redirect();
      });
    },
    isButtonLoading: loading || paymentAccountLoading || paymentPersonsLoading,
    isButtonError: !!error || isPaymentAccountError
  };
};