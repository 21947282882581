import styled from 'styled-components';
const normalizeWidth = width => {
  if (typeof width === 'undefined') {
    return 'unset';
  }
  if (typeof width === 'number') {
    return `${width}px`;
  }
  return width;
};
const StyledRightSidebar = styled.div.withConfig({
  displayName: "StyledRightSidebar",
  componentId: "sc-12b1zwc-0"
})(["background-color:", ";display:flex !important;flex-basis:25%;max-height:100vh;max-width:500px;overflow-y:visible;position:relative;transition:flex 200ms cubic-bezier(0.16,0.84,0.44,1),min-width 200ms cubic-bezier(0.16,0.84,0.44,1);width:unset;will-change:flex;z-index:1;min-width:280px;&[data-collapsed='true']{border-left:", ";flex-basis:", " !important;min-width:", " !important;}"], ({
  theme
}) => theme.color['fill-surface-default'], ({
  theme
}) => `${theme.borderWidth['100']} solid ${theme.color['border-core-default']}`, ({
  collapsedWidth
}) => normalizeWidth(collapsedWidth), ({
  collapsedWidth
}) => {
  return normalizeWidth(collapsedWidth);
});
export default StyledRightSidebar;